import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ReactiveFormsModule } from '@angular/forms';
import { MainService } from '../services/main.service';
import { TableModule } from 'primeng/table';
import { TreeNode } from 'primeng/api';
import {of} from "rxjs";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

  userList: any[] = [];
  createdAt: any;
  userId: any;
  userRole = localStorage.getItem('roleId');
  newPassword: any;
  selectedUsers: any[] = [];
  questionList: any;
  selectedFiltersRole: any;
  tableData: any;
  changePassVisible: boolean = false;
  visibleFil: boolean = false;
  visible: boolean = false;
  changePassVisibles: boolean = false;
  roleId: any;
  statusIds: any;
  iin: any;
  roleList: any;
  usersPermissionsList: any;
  usersPermissionsSelected: any[] = [];
  usersPermissionsTypesList: any;
  usersPermissionsTypesSelected: any[] = [];
  clickedUserId: number = 0;
  clickedId: number = 0;
  clickedUserRoleId: number = 0;
  permissions: any[] = [];
  questSubList!:  TreeNode[];

  allQuestionTypes: any[] = [];
  allSubjectTypes: any[] = [];
  clickedUserSubjectQuestionTypes: any[] = [];

  @ViewChild('dt1') dt: any;


  constructor(private primengConfig: PrimeNGConfig,
    public slideMenuModule: SlideMenuModule,
    private route: ActivatedRoute,
    private main: MainService,
    public router: Router,
    public translate: TranslateService,
    public reactiveFormsModule: ReactiveFormsModule,

  ) { }
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.userFunctionsAuthorityList();

  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  block() {
    const userIds = {
      userIds:
        this.selectedUsers.map((u: any) => u.id)
    }
    this.main.block(userIds)
      .subscribe({
        next: (res: any) => {
          this.userFunctionsAuthorityList();
          this.selectedUsers = []
        },
        error: (err: any) => {
        }
      });
  }

  activate() {
    const userIds = {
      userIds:
        this.selectedUsers.map((u: any) => u.id)
    }
    this.main.activate(userIds)
      .subscribe({
        next: (res: any) => {
          this.userFunctionsAuthorityList();
          this.selectedUsers = []
        },
        error: (err: any) => {
        }
      });
  }

  usersResetPassword() {
    const userIds = {
      userIds:
        this.selectedUsers.map((u: any) => u.id)
    }
    this.main.usersResetPassword(userIds)
      .subscribe({
        next: (res: any) => {
          this.userFunctionsAuthorityList();
          this.selectedUsers = []
        },
        error: (err: any) => {
        }
      });
  }

  changePassword() {
    let user = {}
    user = {
      newPassword: this.newPassword,
      userId: this.tableData.id

    }
    this.main.changePassword(user)
      .subscribe({
        next: (res: any) => {
        }
      })
  }

  userFunctionsAuthorityList() {
    if(this.userRole === '2'){
      this.main.userFunctionsAuthorityList()
      .subscribe({
        next: (res: any) => {
          this.userList = res.filter((obj: any) => {
            return obj.roleId === 3
          })
        },
        error: (err: any) => {
        }
      });
    }else{
      this.main.userFunctionsAuthorityList()
      .subscribe({
        next: (res: any) => {
          this.userList = res;
        },
        error: (err: any) => {
        }
      });
    }
    
  }

  showDialogFil(clickedUserId: number, clickedUserRoleId: number) {
    this.clickedUserId = clickedUserId;
    this.clickedUserRoleId = clickedUserRoleId;
    this.usersPermissions();
    // this.usersPermissionsTypes();

  }

  showDialog(clickedId: number) {
      this.initAllSubjectTypes();
      this.initAllQuestionTypes();
    this.clickedId = clickedId;
    this.expertsQuestionsTypes()

  }



  initAllQuestionTypes() {
      this.main.questionTypes().subscribe(
          (res: any) => {
              this.allQuestionTypes = res;
          },
          (err: any) => {
              console.log(err);
          }
      );
  }

  initAllSubjectTypes() {
      this.main.questionsSubjectsTypes().subscribe(
          (res: any) => {
              this.allSubjectTypes = res;
          },
          (err: any) => {
              console.log(err);
          }
      );
  }

  usersRoles() {
    this.main.usersRoles()
      .subscribe({
        next: (res: any) => {
          this.roleList = res;
        }
      })
  }

  usersPermissions() {
      this.main.usersPermissions(this.clickedUserId)
        .subscribe({
          next: (res: any) => {
            this.usersPermissionsList = res;
            this.visibleFil = true;
            const permiss = [...res];
            this.usersPermissionsSelected = permiss.filter((u:any)=>u.checked);
          }
        })
    }

    usersSetPermissions(){
      this.usersPermissionsSelected.forEach((e: any) => {
        this.permissions.push(
         e.id
        )
    });
      const data = {
        userId: this.clickedUserId,
        permissions: [...new Set(this.permissions)]
      }
      this.main.usersSetPermissions(data)
        .subscribe({
          next: (res: any) => {
            this.userFunctionsAuthorityList();
            this.visibleFil = false
          },
          error: (err: any) => {
          }
        });
    }

    usersSetAdmins(){
      const userIds = {
        userIds:
          this.selectedUsers.map((u: any) => u.id)
      }
    this.main.usersSetAdmins(userIds)
      .subscribe({
        next: (res: any) => {
          this.userFunctionsAuthorityList();
          this.selectedUsers = []
        }
      })
    }

    usersSetOperators(){
      const userIds = {
        userIds:
          this.selectedUsers.map((u: any) => u.id)
      }
    this.main.usersSetOperators(userIds)
      .subscribe({
        next: (res: any) => {
          this.userFunctionsAuthorityList();
          this.selectedUsers = []
        }
      })
    }

    usersSetExperts(){
      const userIds = {
        userIds:
          this.selectedUsers.map((u: any) => u.id)
      }
    this.main.usersSetExperts(userIds)
      .subscribe({
        next: (res: any) => {
          this.userFunctionsAuthorityList();
          this.selectedUsers = []
        }
      })
    }

  isRoleSelected(role: any){
    return this.usersPermissionsList.includes(role)
  }

  expertsQuestionsTypes(){
    this.main.expertsQuestionsTypes(this.clickedId)
    .subscribe({
      next: (res: any) => {
        this.clickedUserSubjectQuestionTypes = res;
          console.log(res);
        this.visible = true;
        this.questionsSubjectsTypes()

      }
    })
  }

  questionsSubjectsTypes(){
    this.main.questionsSubjectsTypes()
    .subscribe({
      next: (res: any) => {
        this.questSubList = res;
      }
    })
  }

  changePassDialog(customer: any) {
    this.changePassVisible = true;
    this.tableData = { ...customer };
  }

  changePassDialogs(customer: any) {
    this.changePassVisibles = true;
    this.tableData = { ...customer };
  }

  visibleFils(customer: any) {
    this.visibleFil = true;
    this.tableData = { ...customer };
  }

  visibleTask(customer: any){
    this.visible = true;
    this.tableData = { ...customer };
  }

  saveExpertQuestionType(expertId: number, subjectId: number, questionTypeId: number) {
      if (this.roleId !== 3) {
          this.main.setExpertQuestionType({expertId:expertId,subjectId:subjectId,questionTypeId:questionTypeId})
              .subscribe(
                  (res: any) => {
                      this.expertsQuestionsTypes();
                  },
                  (err: any) => {
                      console.log(err);
                  }
              );
      }
  }

  deleteExpertQuestionType(expertId: number, subjectId: number, questionTypeId: number) {
      if (this.roleId !== 3) {
          this.main.deleteExpertQuestionType({expertId:expertId,subjectId:subjectId,questionTypeId:questionTypeId})
              .subscribe(
                  (res: any) => {
                      this.expertsQuestionsTypes();
                  },
                  (err: any) => {
                      console.log(err);
                  }
              );
      }
  }

  isExpertHasSubjectQuestionType(expertId: number, subjectId: number, questionTypeId: number): boolean {
      let doesHave = false;
      this.clickedUserSubjectQuestionTypes.forEach(obj => {
         if (
             obj.expertId === expertId &&
             obj.subjectId === subjectId &&
             obj.questionTypeId === questionTypeId
         ) {
             doesHave = true;
         }
      });
      return doesHave;
  }

}
