import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsComponent } from './questions.component';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {TranslateModule} from "@ngx-translate/core";
import { QuestionComponent } from './question/question.component';
import {InputNumberModule} from "primeng/inputnumber";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AngularEditorConfig, AngularEditorModule} from "@kolkov/angular-editor";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {RouterLink} from "@angular/router";
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {TooltipModule} from "primeng/tooltip";
import {SidebarModule} from "primeng/sidebar";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";

@NgModule({
  declarations: [
    QuestionsComponent,
    QuestionComponent
  ],
    imports: [
        CommonModule,
        ButtonModule,
        DialogModule,
        SharedModule,
        CalendarModule,
        TableModule,
        TranslateModule,
        InputNumberModule,
        FormsModule,
        OverlayPanelModule,
        AngularEditorModule,
        InputTextModule,
        DropdownModule,
        ReactiveFormsModule,
        PasswordModule,
        ProgressSpinnerModule,
        RouterLink,
        TooltipModule,
        SidebarModule,
        IconFieldModule,
        InputIconModule
    ],
    exports: [
        QuestionsComponent,
        QuestionComponent
    ]
})
export class QuestionsModule {

}

