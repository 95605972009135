import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {MainService} from "../../services/main.service";
import {Router} from "@angular/router";
import { registerLocaleData } from '@angular/common';

interface Expert{
    id?: any
    questionTypeId?: any
    // createdAt?: any
}

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {


    questions: any;
    roleId: number;
    userId: number;
    questionTypeId: any;
    selectedQuestions: any[] = [];
    selectedExpert!: Expert;
    visible: boolean = false;
    expert: Expert = {};
    expertId: any;
    userList: any [] = [];
    tableData: any;
    id: any;
    visibles: boolean = false;
    visibleFilter: boolean = false;
    newPassword: any;
    // userId: any = localStorage.getItem('userId');
    userStatusId: any = localStorage.getItem('userStatusId');
    repitPassword: any;
    isPassSub: any;
    isPassMatch: any;
    createdAt: any;
    fromDate: any;
    toDate: any;
    questionsDateList: any;
    blockList: any;
    selectBlockList: any;
    allBlocList: any;
    subjectId: any;
    assessmentStatusTypeId: any;
    selectedStatus: any;
    statusList: any;
    selectedUser: any;

    filterByFromDate?: any = null;
    filterByToDate?: any = null;
    filterBySubjectId?: any = null;
    filterByStatus?: any = null;
    filterByExpert?: any = null;

    constructor(private main: MainService,
                public translate: TranslateService,
                private router: Router) {

                }

    ngOnInit(): void {
        this.questions = [];
        // this.selectBlockList = this.questions;
        this.roleId = Number(localStorage.getItem("roleId"));
        this.userId = Number(localStorage.getItem("userId"));
        this.questionsSubjectsTypes();
        this.assessmentsStatuses();
        this.user();

        if(localStorage.getItem('userStatusId') == '3'){
            this.showDialog()
          }
        this.getAllQuestions();

    }

    allAppealListDate() {
        const yyyy = this.fromDate.getFullYear();
        let mm = this.fromDate.getMonth() + 1;
        let dd = this.fromDate.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formatted = yyyy + '-' + mm + '-' + dd;

        const yyyys = this.toDate.getFullYear();
        let mms = this.toDate.getMonth() + 1;
        let dds = this.toDate.getDate();

        if (dds < 10) dds = '0' + dds;
        if (mms < 10) mms = '0' + mms;

        const formattedend = yyyys + '-' + mms + '-' + dds;
        this.filterByFromDate = formatted;
        this.filterByToDate = formattedend;
        console.log(this.roleId === 3);
        console.log(this.roleId == 3);
        console.log(this.userId);
        this.main.getQuestionsWithFilter(
            (this.roleId === 3 ? this.userId : this.filterByExpert),
            this.filterByStatus,
            this.filterBySubjectId,
            formatted,
            formattedend
        )
        .subscribe({
          next: (res: any) => {
            this.questions = res;
          },
          error: (err: any) => {
          }
        });
      }

      questionsSubjectsTypesFilter(){
        this.subjectId = this.selectBlockList.id;
        this.filterBySubjectId = this.selectBlockList.id;
        this.main.getQuestionsWithFilter(
            this.roleId === 3 ? this.userId : this.filterByExpert,
            this.filterByStatus,
            this.subjectId,
            this.filterByFromDate,
            this.filterByToDate
        )
        .subscribe({
            next: (res: any) => {
            this.questions = res;

            }
        })
      }

      assessmentStatusFilter(){
        this.assessmentStatusTypeId = this.selectedStatus.id;
        this.filterByStatus = this.selectedStatus.id;
        this.main.getQuestionsWithFilter(
            this.roleId === 3 ? this.userId : this.filterByExpert,
            this.selectedStatus.id,
            this.filterBySubjectId,
            this.filterByFromDate,
            this.filterByToDate
        )
        .subscribe({
            next: (res: any) => {
            this.questions = res;

            }
        })
      }

      assessmentsStatuses(){
        this.main.assessmentsStatuses()
        .subscribe({
            next: (res: any) => {
                this.statusList = res
            }
        })
      }

      user(){
        this.main.user()
        .subscribe({
            next: (res: any) => {
                this.userList = res
            }
        })
      }

      userExpertFilter(){
        this.expertId = this.selectedUser.id
        this.main.getQuestionsWithFilter(
            this.expertId,
            this.filterByStatus,
            this.filterBySubjectId,
            this.filterByFromDate,
            this.filterByToDate
        )
        .subscribe({
            next: (res: any) => {
            this.questions = res;

            }
        })
      }

      questionsSubjectsTypes(){
        this.main.questionsSubjectsTypes()
        .subscribe({
            next: (res: any) => {
                this.blockList = res
            }
        })
      }

    getAllQuestions(){
        this.filterBySubjectId = null;
        this.filterByFromDate = null;
        this.filterByToDate = null;
        this.filterByExpert = null;
        this.filterByStatus = null;
        if (this.roleId === 3) {
            this.main.getAllQuestions(this.userId).subscribe(
                (res: any): void => {
                    // @ts-ignore
                    this.questions = res.filter(obj => {
                        return obj.statusId === 1 || obj.statusId === 2 || obj.statusId === 3 || obj.statusId === 5
                    });
                },
                (err: any): void  => {
                }
            );
        } else{
            this.main.getAllQuestions()
            .subscribe(
                (res: any): void => {
                    this.questions = res;
                },
                (err: any): void  => {
                }
            );
        }
    }

    goToQuestion(id: number): void {
        this.router.navigate(['questions/' + id]);
    }

    getUsers(){
        this.main.getUsers(3, this.selectedQuestions.map((u: any) => u.questionTypeId))
        .subscribe({
            next: (res: any) => {
                this.userList = res;
            }
        })
    }

    questionsSetExpert(){
        const data = {
        questionIds: this.selectedQuestions.map((u: any) => u.testGradeId),
        expertId: this.selectedExpert.id
      }
        this.main.questionsSetExpert(data)
        .subscribe({
            next: (res: any) => {
                this.selectedQuestions = []
                this.getAllQuestions()
                this.visible = false
            }
        })
    }

    showDialog() {
        this.visibles = true;
    }

    changePassword(){
      this.isPassSub = true
      let user = {}
      user = {
        newPassword: this.newPassword,
        userId: this.userId

      }
      if(this.isPasswordmatch()){
        this.main.changePassword(user)
      .subscribe({
        next: (res: any) => {
        this.visible = false;
        this.logOut()

        }
      })
      }
    }

    logOut() {
        this.main.exit()
            .subscribe((res: any) => {
                localStorage.clear();
                window.location.href = './login';
            }, (err: any) => {
            });
    }

    isPasswordmatch(){
      this.isPassMatch = this.newPassword === this.repitPassword
      return this.isPassMatch
    }

    openFilter(){
        this.visibleFilter = true
    }

    visibleModal(){
        this.visible = true;
        this.getUsers();
    }

}
