<div class="grid">
    <p-dialog header="{{ 'ChangePasswordUser' | translate }}" [closable]="false" [modal]="true" [(visible)]="visible"
        [style]="{width: '30vw'}">
        <div class="text-center">
            <span class="p-float-label my-6">
                <input [(ngModel)]="newPassword" [style]="{'width': '70%'}" pInputText>
                <label [style]="{'width': '50%'}" htmlFor="username"> {{ 'Новый пароль' | translate }} </label>
            </span>
            <span class="p-float-label my-6">
                <p-password inputStyleClass="w-12" class="passClass" [toggleMask]="true" [style]="{'width': '70%'}"
                    [ngClass]="{'ng-invalid ng-dirty': isPassSub && isPassMatch}" [(ngModel)]="repitPassword"
                    [feedback]="false"></p-password>
                <label [style]="{'width': '65%'}">{{ 'ConfirmYourNewPassword' | translate }}</label>
            </span>
            <p-button [style]="{'width': '70%'}" class="text-center" (onClick)="changePassword()"> {{ 'ChangePassword' | translate }}
            </p-button>
        </div>
    </p-dialog>
    <div class="col-12 xl:col-12">
        <div class="card">
            <div class="border-round mt-4 border-1 border-gray-300" style="width: 100%; height: 100px;">
                <span class="cursor-pointer underline hover:text-indigo-900 flex mt-5 justify-content-center text-2xl">
                    {{ 'InstructionsForTheOlympiadWebApplication' | translate }} </span>
            </div>
            <div class="mt-5 flex justify-content-center">
                <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3"
                    style="background-color: #3B82F6; height: 3px;"></div>
                <div
                    class="text-2xl justify-content-center mx-4 flex align-items-center justify-content-center border-round">
                    {{ 'Regulations' | translate }} </div>
                <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3"
                    style="background-color: #3B82F6; height: 3px;"></div>
            </div>
            <div class="border-round mt-1 mx-auto border-1 border-gray-300 mt-5" style="width: 100%; height: 100px;">
                <a target="_blank" href="http://zan.gov.kz/client/#!/doc/169979/rus"
                    class="text-blue-500 cursor-pointer underline hover:text-indigo-900 flex ml-1 mt-3 justify-content-center text-base">
                    {{ 'InternationalOlympiads' | translate }}
                </a>
            </div>
            <div class="border-round mt-1 mx-auto border-1 border-gray-300 mt-5" style="width: 100%; height: 100px;">
                <a target="_blank" href="https://adilet.zan.kz/rus/docs/V1100007355"
                    class="text-blue-500 cursor-pointer underline hover:text-indigo-900 flex ml-3 mt-3 justify-content-center text-base">
                    {{ 'RepublicanAndInternational' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>