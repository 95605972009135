<div class="card flex bg-gray-300 text-center justify-content-center" style="height: 950px;">
    <p-card class="" [style]="{'margin-top': '30%', 'height': '30%'}"  header=" {{ 'NoConnectionAppnct' | translate }} {{ 'RunAProgramm' | translate }}">
        <div class="">
            <p>Если приложение установлено, то запустите его и нажмите "проверить соединение" </p>
            <a class="w-1/6" [routerLink]="ref">
                <p-button (click)="CheckConnection()" label="{{ 'CheckConnection' | translate }}" icon="pi pi-refresh"
                    iconPos="right" class="mr-3"></p-button>
            </a>

                <a target="_blank" href="./../../assets/setup_appnct.exe">
                    <p-button  label="{{ 'ClickToInstall' | translate }}" icon="pi pi-download"
                    iconPos="right"></p-button>
                </a>
           
            <!-- <a class="w-1/6" [routerLink]="ref">
                <p-button (click)="CheckConnection()" label="{{ 'CheckConnection' | translate }}" icon="pi pi-check"
                    iconPos="right"></p-button>
            </a> -->
        </div>
    </p-card>
</div>

<!-- <div class="text-center mt-80">
    <label class="text-3xl">
        {{ 'NoConnectionAppnct' | translate }}
        <p> {{ 'RunAProgramm' | translate }} </p>
    </label>

    <div class="flex margClass">
        <a class="w-1/6" [routerLink]="ref">
            <button (click)="CheckConnection()" class="mt-20 mx-20 buttonA rounded-lg bg-blue-300 p-2">
                {{ 'CheckConnection' | translate }}
            </button>
        </a>
        <a class="w-1/6" [routerLink]="ref">
            <button (click)="back()" class="mt-20 mx-20 rounded-lg buttonB bg-blue-300 p-2">
                {{ 'buttText' | translate }}
            </button>
        </a>
    </div>

    <div class="cursor-pointer underline cvet mt-16 text-base text-blue">
        <span><a target="_blank" href="./../../assets/setup_appnct.exe">
                {{ 'ClickToInstall' | translate }}
            </a></span>
    </div>
</div> -->