import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MainService } from '../app/services/main.service';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private primengConfig: PrimeNGConfig,
        public route: ActivatedRoute,
        public router: Router,
        public main: MainService,
        public translate: TranslateService,
        private titleService: Title

    ) {

        translate.addLangs(['ru', 'kz']);
        translate.setDefaultLang('ru');

        const browserLang: any = translate.getBrowserLang();
        translate.use(browserLang.match(/ru|kz/) ? browserLang : 'ru');
        this.changeLang(localStorage.getItem('Lang'));
    }

    ngOnInit() {
        this.titleService.setTitle("english-test");
        this.primengConfig.ripple = true;

        if (localStorage.getItem('token') == undefined || localStorage.getItem('token') == 'null') {
            this.router.navigate(['/login']);
        }
        setInterval(() => {
            this.CheckConnection();

        }, 60000
        )
    }

    changeLang(lang: any = 'kz') {
        this.translate.use(lang);
        localStorage.setItem('Lang', lang);
    }

    CheckConnection(){
        this.main.getLocalHost()
        .subscribe((res: any) => {
          localStorage.setItem('userInfoToken', res.token);
        }, (err: any) => {
         this.router.navigate(['/connect']);
        });
      }
}
