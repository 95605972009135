import { isDevMode } from "@angular/core";
import {environment} from "../../environments/environment";

export class apiRoutes {

  [x: string]: any;
    backend: any;
    autheticationControllerRoutes: any;
    routes: any;

    constructor(){
        if (isDevMode()) {
            // this.backend = 'http://localhost:8088'; // путь для LocalHost
            // this.backend = 'http://192.168.200.13:28080/kaztest/assessment/api/v1'; // путь для LocalHost
            //this.backend = './kaztest/assessment/api/v1'; был до этого
            this.backend = './englishkz/assessment/api/v1';
        }
        if (!isDevMode()) {
            //this.backend = './kaztest/assessment/api/v1'; был до этого
            this.backend = './englishkz/assessment/api/v1';
        }

        this.autheticationControllerRoutes = {
            getToken: this.backend + '/getToken'
        }

        this.routes = {}
    }

}

