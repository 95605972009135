<div class="grid p-fluid">
     <p-dialog header="{{ 'ChangePasswordUser' | translate }}" [closable]="false" [modal]="true" [(visible)]="visibles"
            [style]="{width: '30vw'}">
            <div class="text-center">
                <span class="p-float-label my-6">
                    <input [(ngModel)]="newPassword" [style]="{'width': '70%'}" pInputText>
                    <label [style]="{'width': '50%'}" htmlFor="username"> {{ 'Новый пароль' | translate }} </label>
                </span>
                <span style="margin-left: 15%;" class="p-float-label my-6">
                    <p-password [toggleMask]="true" [style]="{'width': '83%'}"
                        [ngClass]="{'ng-invalid ng-dirty': isPassSub && isPassMatch}" [(ngModel)]="repitPassword"
                        [feedback]="false"></p-password>
                    <label >{{ 'ConfirmYourNewPassword' | translate }}</label>
                </span>
                <p-button [style]="{'width': '70%'}" class="text-center" (onClick)="changePassword()"> {{ 'ChangePassword' | translate }}
                </p-button>
            </div>
        </p-dialog>
    <div class="col-12 md:col-12">
        <div class="card">
            <div class="my-3">
                <div class="mt-5 flex justify-content-center">
                    <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
                        style="background-color: #3B82F6; height: 3px;"></div>
                    <div
                        class="text-2xl justify-content-center mx-5 flex align-items-center justify-content-center border-round uppercase">
                         {{ 'questions' | translate }} </div>
                    <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
                        style="background-color: #3B82F6; height: 3px;"></div>
                </div>
            </div>

            <p-dialog header="{{ 'PurposeOfTheTask' | translate}}" [(visible)]="visible" [style]="{width: '30vw', height: '20vw'}">
                <span class="p-float-label my-5">
                    <p-dropdown [filter]="true" [options]="userList" [showClear]="true" [(ngModel)]="selectedExpert"
                        placeholder="{{ 'selectExpert' | translate }}" optionLabel="username" inputId="float-label" id="float-label">
                        <ng-template let-option pTemplate="dropdown">
                            <span>{{ option.username }} - {{ option.roleName }} - {{ 'iin' | translate }} - {{ option.iin }}</span>
                        </ng-template>
                    </p-dropdown>
                    <label for="float-label">{{ 'selectExpert' | translate }}</label>
                </span>
                <div class="text-center my-4">
                    <p-button (onClick)="questionsSetExpert()" [style]="{'width': '60%'}" class="text-center">
                        {{ 'Assign' | translate}} </p-button>
                </div>
            </p-dialog>
            <p-overlayPanel #op>
                <ng-template pTemplate="content">
                    <p>От</p>
                    <p-calendar dateFormat="yy-mm-dd" (click)="allAppealListDate()" [(ngModel)]="fromDate" [showIcon]="true"></p-calendar>
                    <p>До</p>
                    <p-calendar dateFormat="yy-mm-dd" (click)="allAppealListDate()" [(ngModel)]="toDate"
                        [showIcon]="true"></p-calendar>
                </ng-template>
            </p-overlayPanel>
            <p-overlayPanel #od>
                <ng-template pTemplate="content">
                    <span class="p-float-label">
                        <p-dropdown [options]="blockList" [(ngModel)]="selectBlockList" placeholder="Выбор блока"
                                    optionLabel="{{ translate.currentLang == 'ru' ? 'nameRu' : 'nameKaz' }}" inputId="float-label"></p-dropdown>
                        <label for="float-label">Выбор блока</label>
                        <button class="ml-2" (click)="questionsSubjectsTypesFilter()" type="button" pButton
                        label="Сортировать"></button>
                    </span>
                </ng-template>
            </p-overlayPanel>
            <p-overlayPanel #oy>
                <ng-template pTemplate="content">
                    <span class="p-float-label">
                        <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" placeholder="Выбор статуса"
                                    optionLabel="{{ translate.currentLang == 'ru' ? 'nameRu' : 'nameKaz' }}" inputId="float-label"></p-dropdown>
                        <label for="float-label">Выбор статуса</label>
                        <button class="ml-2" (click)="assessmentStatusFilter()" type="button" pButton
                        label="Сортировать"></button>
                    </span>
                </ng-template>
            </p-overlayPanel>
            <p-overlayPanel #ou>
                <ng-template pTemplate="content">
                    <span class="p-float-label">
                        <p-dropdown [options]="userList" [(ngModel)]="selectedUser" placeholder="Выбор эксперта" optionLabel="{{ translate.currentLang == 'ru' ? 'username' : 'username' }}" inputId="float-label"></p-dropdown>
                        <label for="float-label">Выбор эксперта</label>
                        <button class="ml-2" (click)="userExpertFilter()" type="button" pButton
                                label="Сортировать"></button>
                    </span>
                </ng-template>
            </p-overlayPanel>
            <p-table #dt1 [(selection)]="selectedQuestions" dataKey="testGradeId" [rowHover]="true" [value]="questions"
                [paginator]="true" [scrollable]="true" scrollHeight="580px" [tableStyle]="{ 'min-width': '75rem' }"
                [rows]="10" [showCurrentPageReport]="true"
                currentPageReportTemplate="{first}-ден {last}-ға дейінгі тапсырмалар (барлығы {totalRecords})"
                [globalFilterFields]="['testGradeId','subjectNameRu','questionNumber','assessmentStatusTypeNameKaz', 'expertUsername','assessmentStatusTypeNameRu', 'subjectNameKaz']"
                [rowsPerPageOptions]="[10, 25, 50]">
                <ng-template pTemplate="caption">
                    <div class="flex">
                        <input
                            class="mb-3"
                            [style]="{'width':'15%'}"
                            pInputText
                            type="text"
                            (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Поиск" />
                    </div>
                    <p-button  *ngIf="roleId !== 3" (onClick)="visibleModal()" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-asterisk"
                        label="{{ 'assignTask' | translate }}" size="small"></p-button>
                        <p-button (onClick)="getAllQuestions()" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-asterisk"
                        label="{{ 'UpdateList' | translate }}" size="small"></p-button>
                        <p-button (click)="op.toggle($event)" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-asterisk"
                        label="{{ 'FilterByDate' | translate }}" size="small"></p-button>
                        <p-button (click)="od.toggle($event)" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-asterisk"
                        label="{{ 'FilterByBlock' | translate }}" size="small"></p-button>
                        <p-button (click)="oy.toggle($event)" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-asterisk"
                        label="{{ 'FilterByStatus' | translate }}" size="small"></p-button>
                        <p-button *ngIf="roleId !== 3" (click)="ou.toggle($event)" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-asterisk"
                        label="{{ 'FilterByExpert' | translate }}" size="small"></p-button>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngIf="roleId !== 3" style="width: 1%">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th style="width: 3%" pSortableColumn="testGradeId"> Код
                            <p-sortIcon field="testGradeId"></p-sortIcon>
                        </th>
                        <th style="width: 5%" pSortableColumn="createdAt"> {{ 'JobCreationTime' | translate }}
                            <p-sortIcon field="createdAt"></p-sortIcon>
                        </th>
                        <th style="width: 3%" pSortableColumn="subjectNameRu"> {{ 'BlockTask' | translate }}
                            <p-sortIcon field="subjectNameRu"></p-sortIcon>
                        </th>
                        <th style="width: 3%" pSortableColumn="questionNumber"> {{ 'JobNumber' | translate }}
                            <p-sortIcon field="questionNumber"></p-sortIcon>
                        </th>
                        <th style="width: 5%" pSortableColumn="assessmentStatusTypeNameRu"> {{ 'status' | translate }}
                            <p-sortIcon field="assessmentStatusTypeNameRu"></p-sortIcon>
                        </th>
                        <th style="width: 10%" pSortableColumn="expertUsername"> {{ 'Expert' | translate}}
                            <p-sortIcon field="expertUsername"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-question>
                    <tr
                        [ngStyle]="{'background': (question.statusId === 6) ? 'rgba(254,230,202,0.82)' : (question.statusId === 7) ? 'rgba(187,255,192,0.82)' : ''}">
                        <td  *ngIf="roleId !== 3">
                            <p-tableCheckbox [value]="question"
                                             [disabled]="question.statusId === 7 || question.statusId === 6">
                            </p-tableCheckbox>
                        </td>
                        <td (click)="goToQuestion(question.testGradeId)">{{ question.testGradeId }}</td>
                        <td (click)="goToQuestion(question.testGradeId)">{{ question.createdAt | date:'short' }}</td>
                        <td (click)="goToQuestion(question.testGradeId)">
                            <span *ngIf="translate.currentLang == 'kz'"> {{ question.subjectNameKaz }} </span>
                            <span *ngIf="translate.currentLang == 'ru'"> {{ question.subjectNameRu }} </span>
                        </td>
                        <td (click)="goToQuestion(question.testGradeId)">{{ question.questionNumber }}</td>
                        <td (click)="goToQuestion(question.testGradeId)">
                            <span *ngIf="translate.currentLang == 'kz'"> {{ question.assessmentStatusTypeNameKaz }} </span>
                            <span *ngIf="translate.currentLang == 'ru'"> {{ question.assessmentStatusTypeNameRu }} </span>
                        </td>
                        <td (click)="goToQuestion(question.testGradeId)">{{ question.expertUsername }}</td>
<!--                        <td (click)="goToQuestion(question.testGradeId)">{{ question.confirmerUsername }}</td>-->

                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                </ng-template>
                <ng-template pTemplate="paginatorright">
                </ng-template>
            </p-table>

        </div>
    </div>
</div>
