<div class="grid p-fluid">
    <div class="col-12">
        <p-button
            class="goback-button" icon="pi pi-arrow-left" severity="info" [routerLink]="'/questions'"></p-button>
        <div class="card" *ngIf="question !== undefined">

            <div class="my-3">
                <div class="mt-5 flex justify-content-center">
                    <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
                         style="background-color: #3B82F6; height: 3px;"></div>
                    <div
                        class="text-2xl justify-content-center mx-3 flex align-items-center justify-content-center border-round uppercase">
                        {{ 'JobСode' | translate }}: {{ questionId }} </div>
                    <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
                         style="background-color: #3B82F6; height: 3px;"></div>
                </div>
                <div class="m-5">
                    <!-- {{ translate.currentLang == 'ru' ? 'subjectNameRu' : 'subjectNameKaz' }} -->
                    <h4>{{ 'BlockTask' | translate}} -
                        <span *ngIf="translate.currentLang == 'kz'"> {{ question.subjectNameKaz }} </span>
                        <span *ngIf="translate.currentLang == 'ru'"> {{ question.subjectNameRu }} </span>  </h4>
                    <p class="font-bold text-lg">{{ question.questionNumber }} - {{ 'Question' | translate }}</p>
                </div>

                <div class="m-5">
                    <div [innerHTML]="getSanitizedHtml(questionContent)"></div>
                </div>
                <table class="mr-6 mt-6 ml-6 w-11">
                    <tr class="font-bold bg-yellow-200" >
                        <td  style="width: 50%">
                            {{ 'TestTakerAnswer' | translate }}:
                            <div *ngIf="question.subjectId === 4 && question.questionTextAnswer !== undefined"
                                class="inline p-1 bg-white border-round-xl text-base">
                                <span class="p-1">
                                    {{countWordsInHtml(question.questionTextAnswer)}}
                                </span>
                                сөз
                            </div>
                        </td>
                        <td *ngIf="question.subjectId === 4">
                            {{ 'ExpertFixes' | translate }}:
                        </td>
                    </tr>
                    <tr>
                        <td class="p-2">
                            <div *ngIf="question.subjectId === 5 && question.questionTextAnswer !== undefined">
                                <audio
                                    controls
                                    controlsList="nodownload noremoteplayback noplaybackrate foobar">
                                    <source [src]="audioServerLink + question.questionTextAnswer" type="audio/webm" />
                                </audio>
                            </div>
                            <div *ngIf="question.subjectId === 4 && question.questionTextAnswer !== undefined" class="text-lg"
                                 [innerHTML]="getSanitizedHtml(question.questionTextAnswer)">
                            </div>

                        </td>
                        <td (click)="onOpenEditCorrection()"
                            *ngIf="question.subjectId === 4"
                            [ngClass]="{'hover:bg-gray-200 cursor-pointer':statusId === 2}"
                            class="p-4" >
                            <div *ngIf="expertCorrection !== null" [innerHTML]="getSanitizedHtml(expertCorrection)">
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="flex" *ngIf="isQuestionBelongsExpert &&  question.subjectId === 4 && statusId === 2">
                    <p-button
                        class="ml-6"
                        (click)="onOpenEditCorrection()"
                        label="{{'CorrectTheTestTakersErrors' | translate }}"></p-button>
                </div>

                <div *ngIf="isQuestionBelongsExpert" class="mt-4">

                    <div class="m-auto w-11" *ngIf="isStartWorkEnable">
                        <p-button (click)="startAssessing()" label="{{ 'StartAssessment' | translate }}"></p-button>
                    </div>

                    <div  *ngIf="statusId === 2" class="mt-5">
                        <!-- Criterion info -->
                        <p-sidebar [(visible)]="isShowCriterionInfo" [style]="{'width':'40%'}">
                            <div class="flex mt-8" *ngIf="currentCriterionInfo !== undefined && currentCriterionInfo !== null" >
                                <div [innerHtml]="getSanitizedHtml(currentCriterionInfo.descrHtml)">{{currentCriterionInfo.descHtml}}</div>
                                <div (click)="isShowCriterionInfo = false"
                                     [style]="{'border-radius':'20px','display': 'flex', 'align-items':'center', 'justify-content':'center'}"
                                    class="flex items-center justify-center h-2/3 w-1 ml-3 bg-blue-200 rounded-lg cursor-pointer">
                                <div><i class="pi pi-arrow-left text-white font-bold text-xl"></i></div>
                                </div>
                            </div>
                        </p-sidebar>
                        <div class=" mb-4 mt-5 flex justify-content-center">
                            <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
                                 style="background-color: #3B82F6; height: 3px;"></div>
                            <div
                                class="text-2xl justify-content-center mx-3 flex align-items-center justify-content-center border-round uppercase">
                                {{ 'RateTheTask' | translate }} </div>
                            <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
                                 style="background-color: #3B82F6; height: 3px;"></div>
                        </div>
                        <!-- Download pdf file criteria -->
                        <div *ngIf="question.subjectId === 4" tooltipPosition="left"
                             pTooltip="Критерийлер файлын жүктеу үшін басыңыз">
                            <a href="./assets/Критерии-Жазылым.pdf" target="_blank">
                                <i class="ml-5 text-lg pi pi-download text-blue-400 cursor-pointer underline">  Критерийлер файлын жүктеп алыңыз</i>
                            </a>
                        </div>
                        <div *ngIf="question.subjectId === 5" tooltipPosition="left"
                             pTooltip="Критерийлер файлын жүктеу үшін басыңыз">
                            <a href="./assets/Критерии-Айтылым.pdf" target="_blank">
                                <i class="ml-5 text-lg pi pi-download text-blue-400 cursor-pointer underline">  Критерийлер файлын жүктеп алыңыз</i>
                            </a>
                        </div>

                        <div class="m-5" *ngIf="questionCriterionList.length > 0">
                            <div class="flex align-items-center mb-1">
                                <div class="m-1 w-5 font-medium text-base p-1" >{{ 'Criterion' | translate }}</div>
                                <div class="m-1 w-3 font-medium text-base p-1 pl-4">{{ 'Score/MaximumScore' | translate }}</div>
                                <div class="m-1 w-8 font-medium text-base p-1" >{{ 'RationaleForTheAssessment' | translate }}</div>
                            </div>
                            <div *ngFor="let criterion of questionCriterionList; let i = index">
                                <div class="flex align-items-center shadow-2 border-round-xl mb-4"
                                  [ngClass]="{'border-3 border-green-300 shadow-5':criterion.isGradeSet,
                                        'border-2 border-red-400':
                                        (!isCriterionValid && criterion.criterionScoreDescription === null) ||
                                        (!isCriterionValid && criterion.criterionScoreDescription.length < 1)}">
                                    <div pTooltip="Берілген критерий туралы толық ақпарат алу үшін басыңыз"
                                         tooltipPosition="left" (click)="showCriterionInfo(criterion)"
                                         class="h-full cursor-pointer m-1 w-7 font-medium text-base px-2" >
                                        <i (click)="showCriterionInfo(criterion)"
                                            class="cursor-pointer mr-2 pi pi-info-circle text-blue-400 font-bold text-lg" ></i>
                                        {{criterion.nameKaz}}
                                    </div>
                                    <div class="relative pr-2 w-4 ml-1 pl-0 pr-4 border-left-3 border-gray-300 pt-3 h-6rem">
                                        <p-inputNumber
                                            [min]="0"
                                            [max]="questionCriterionList[i].maxScore"
                                            [(ngModel)]="questionCriterionList[i].criterionScore"
                                            [showButtons]="true"
                                            buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal"
                                            [step]="1"
                                            decrementButtonClass="bg-red-300 border-none"
                                            incrementButtonClass="bg-green-300 border-none"
                                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                        <div class="absolute text-2xl" style="top: 35%; right: 8%">
                                            / {{questionCriterionList[i].maxScore}}
                                        </div>
                                    </div>
                                    <span
                                        *ngIf="(!isCriterionValid && criterion.criterionScoreDescription === null) ||
                                        (!isCriterionValid && criterion.criterionScoreDescription.length < 1)"
                                        class="text-xs text-red-400">{{ 'FillInTheExplanation' | translate }}!</span>
                                    <textarea class="border-round-right ml-1 border-left-3 border-gray-300 w-full h-6rem p-2"
                                              [(ngModel)]="criterion.criterionScoreDescription" ></textarea>
                                </div>
                            </div>
                            <div class="flex mt-1" >
                                <p-button
                                    [loading]="saveCriterionButtonLoading"
                                    severity="info"
                                    (click)="onClickSaveAllCriterion()"
                                    label="{{ 'SaveGradingCriteria' | translate }}"></p-button>
                                <p-button
                                    class="ml-4"
                                    [loading]="savePrevGradeButtonLoading"
                                    severity="success"
                                    (click)="savePrevTestGrade()"
                                    label="{{ 'CompleteAssessment' | translate}}"></p-button>
                            </div>
                        </div>
                    </div>
                    <div  *ngIf="statusId === 3" class="m-4">
                        <div class=" mb-4 flex justify-content-center">
                            <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-2"
                                 style="background-color: #3B82F6; height: 3px;"></div>
                            <div
                                class="text-2xl justify-content-center mx-3 flex align-items-center justify-content-center border-round uppercase">
                                 {{ 'CheckYourAssessment' | translate}} </div>
                            <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-2"
                                 style="background-color: #3B82F6; height: 3px;"></div>
                        </div>
                        <div class="flex align-items-center mb-1">
                            <div class="m-1 w-6 font-medium text-base p-1" >{{ 'Criterion' | translate }}</div>
                            <div class="m-1 w-5 font-medium text-base p-1 pl-8">{{ 'Score/MaximumScore' | translate }}</div>
                            <div class="m-1 w-8 font-medium text-base p-1" >{{ 'RationaleForTheAssessment' | translate }}</div>
                        </div>
                        <div  *ngFor="let criterion of questionCriterionList; let i = index">
                            <div class="flex align-items-center shadow-4 border-round-xl mb-4">
                                <p-overlayPanel #op>Something</p-overlayPanel>
                                <div class="m-1 w-7 font-medium text-base p-2" >
                                    {{criterion.nameKaz}}
                                </div>
                                <div class="relative pr-2 w-3 ml-1 pl-0 pr-4 border-left-3 border-gray-300 pt-3 flex-grow-1"
                                      style="height: 100%">
                                    <p-inputNumber
                                        [disabled]="true"
                                        [min]="0"
                                        [max]="questionCriterionList[i].maxScore"
                                        [(ngModel)]="questionCriterionList[i].criterionScore"
                                        [showButtons]="true"
                                        buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal"
                                        [step]="1"
                                        decrementButtonClass="bg-red-200 border-none"
                                        incrementButtonClass="bg-green-200 border-none"
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                    <div class="absolute text-2xl" style="top: 35%; right: 8%;">
                                        / {{questionCriterionList[i].maxScore}}
                                    </div>
                                </div>
                                <div class="w-10 border-left-3 border-gray-300 p-4 text-lg">
                                    <p style="word-wrap: break-word; word-break: break-all">
                                        {{criterion.criterionScoreDescription}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="flex mt-5">
                            <div class="">
                                <p-button (click)="startAssessing()" label="{{ 'MakeEdits' | translate }}" severity="info"></p-button>
                            </div>
                            <div class="ml-4">
                                <p-button
                                    (click)="isExpertConfirmModalOpen = true; generateCaptcha()"
                                    severity="success"
                                    label="{{ 'ConfirmTheAssessment' | translate}}"></p-button>
                            </div>
                        </div>
                    </div>
                </div>






                <div *ngIf="!isQuestionBelongsExpert" class="mt-4 mx-5">
                    <!-- preview overall score -->
                    <div *ngIf="questionCriterionList.length > 0" class="font-bold my-5 text-2xl">
                        {{ 'allBall' | translate}}: {{ question.testScoreId }}
                    </div>

                    <!-- expert criterion heading -->
                    <div *ngIf="questionCriterionList.length > 0" class="flex align-items-center mb-1">
                        <div class="m-1 w-3 font-semibold text-lg" >Критерий</div>
                        <div class="ml-8 pl-7 w-2 font-semibold text-lg">Балл</div>
                        <div class="ml-7 font-semibold text-lg">{{ 'reason' | translate}}</div>
                    </div>
                    <!-- end of the expert criterion heading -->


                    <!-- preview  expert-->
                    <div  *ngFor="let criterion of questionCriterionList; let i = index">
                        <div class="flex align-items-center shadow-4 border-round-xl mb-4">
                            <div class="m-1 w-4 font-medium text-base p-2" >
                                <span *ngIf="translate.currentLang == 'kz'"> {{ criterion.nameKaz }} </span>
                                <span *ngIf="translate.currentLang == 'ru'"> {{ criterion.nameRu }} </span>
                                </div>
                            <div class="relative pr-5 w-2 ml-1 pl-0 border-left-3 border-gray-300 pt-3">
                                <p-inputNumber
                                    [style]="{border: '1px solid gray','border-radius': '20px'}"
                                    [disabled]="true"
                                    [(ngModel)]="questionCriterionList[i].criterionScore"></p-inputNumber>
                                <div class="absolute text-xl text-gray-500" style="top: 40%; right: 9%">
                                    / {{questionCriterionList[i].maxScore}}
                                </div>
                            </div>
                            <div class="w-10 border-left-3 border-gray-300 p-4 text-lg">
                                <p style="word-wrap: break-word; word-break: break-all">
                                    {{criterion.criterionScoreDescription}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex" *ngIf="statusId === 4 && (roleId === 1 || roleId === 2)">
                    <p-button class="m-4" (onClick)="isOpenConfirmByOperatorModal = true; generateCaptcha();"
                              label="{{ 'ConfirmTheExpertsAssessment' | translate}}"
                              severity="success"
                    ></p-button>
                    <p-button class="m-4"
                              severity="warning"
                              (onClick)="isOpenRejectByOperatorModal = true; generateCaptcha();"
                              label="{{'SendForRevision' | translate }}"></p-button>
                </div>

            </div>
        </div>
        <div class="card" *ngIf="question !== undefined && roleId === 3 && !isQuestionBelongsExpert">
            <h3 class="text-center font-bold text-red-500">{{ 'notForYou' | translate }}</h3>
        </div>
    </div>
</div>






<!-- Correct the student  answer -->
<p-dialog header="{{ 'CorrectTheMistakes' | translate}}"
          [(visible)]="isCorrectionEditorOpen"
          [modal]="true"
          [style]="{ width: '95vw', height: '70vw' }" [draggable]="false" [resizable]="false"
          (onHide)="isCorrectionEditorOpen = false; initQuestion()">
    <div class="mx-5">
        <div class="w-full p-4 border-2 border-gray-300 bg-yellow-200 font-bold">{{ 'TestTakerAnswer' | translate }}</div>
        <div *ngIf="question !== undefined && question.questionTextAnswer !== undefined"
             class="w-full p-4 border-2 border-gray-300 border-top-none"
             [innerHTML]="getSanitizedHtml(question.questionTextAnswer)">
        </div>
        <div class="w-full mt-3 p-1">
            <p-button (click)="onCopyAnswerToCorrection()" class="bg-gray-300">
                <i class="pi pi-copy">{{ 'CopyTheTest' | translate}}</i>
            </p-button>
        </div>
    </div>

    <div class="m-5">
        <angular-editor
            [placeholder]="'Текстовый редактор...'"
            [(ngModel)]="expertCorrection"
            [config]="editorConfig"></angular-editor>
    </div>

    <div class="flex text-center ml-5 mt-4">
        <p-button
                  severity="info"
                  [loading]="saveCorrectionButtonLoading"
                  (onClick)="isCorrectionEditorOpen = false; initQuestion()"
                  label="{{ 'Back' | translate }}"></p-button>
        <p-button class="ml-5"
                  [loading]="saveCorrectionButtonLoading"
                  severity="success"
                  (onClick)="saveExpertCorrection(expertCorrection)"
                  label="{{ 'SaveChanges' | translate }}"></p-button>
    </div>
</p-dialog>


<!-- expert confirm modal -->
<p-dialog
          [(visible)]="isExpertConfirmModalOpen"
          [modal]="true"
          [style]="{ width: '20vw', height: '20vw' }" [draggable]="false" [resizable]="false"
          (onHide)="isExpertConfirmModalOpen = false; isConfirmButtonDisabled = true; inputCaptcha = 0">
    <div class="m-3 text-xl font-semibold">
        {{ 'ConfirmYourAssessment' | translate }}</div>

    <div class="mx-5 mt-5">
        {{x}} + {{y}} = ?
        <div >
            <input type="number" [(ngModel)]="inputCaptcha">
        </div>
    </div>

    <div class="flex mx-auto text-center">
        <div class="m-4">
            <p-button [disabled]="inputCaptcha !== sum"
                      (click)="onConfirmAssessmentByExpert()" severity="danger" label="{{ 'Confirm' | translate }}"></p-button>
        </div>
        <div class="m-4">
            <p-button (click)="isExpertConfirmModalOpen = false; isConfirmButtonDisabled = true; inputCaptcha = 0" severity="info" label="{{ 'Cancel' | translate }}"></p-button>
        </div>
    </div>
</p-dialog>


<!-- operator confirm modal -->
<p-dialog
    [(visible)]="isOpenConfirmByOperatorModal"
    [modal]="true"
    [style]="{ width: '20vw', height: '20vw' }" [draggable]="false" [resizable]="false"
    (onHide)="isOpenConfirmByOperatorModal = false; isConfirmByOperatorButtonDisabled = true; inputCaptcha = 0">
    <div class="m-3 text-xl font-semibold">
        {{ 'ConfirmTheWork' | translate }}
    </div>

    <div class="mx-5 mt-5">
        {{x}} + {{y}} = ?
        <div >
            <input type="number" [(ngModel)]="inputCaptcha">
        </div>
    </div>


    <div class="flex mx-auto text-center">
        <div class="m-4">
            <p-button [disabled]="inputCaptcha !== sum"
                      (click)="onConfirmAssessmentByOperator()"
                      severity="danger" label="{{ 'Confirm' | translate }}"></p-button>
        </div>
        <div class="m-4">
            <p-button (click)="isOpenConfirmByOperatorModal = false; isConfirmByOperatorButtonDisabled = true; inputCaptcha = 0"
                      severity="info" label="{{ 'Cancel' | translate }}"></p-button>
        </div>
    </div>
</p-dialog>


<!-- operator reject modal -->
<p-dialog
    [(visible)]="isOpenRejectByOperatorModal"
    [modal]="true"
    [style]="{ width: '20vw', height: '20vw' }" [draggable]="false" [resizable]="false"
    (onHide)="isOpenRejectByOperatorModal = false; isRejectByOperatorButtonDisabled = true; inputCaptcha = 0">
    <div class="m-3 text-xl font-semibold">
        {{ 'SendItToAnExpert' | translate }}
    </div>

    <div class="mx-5 mt-5">
        {{x}} + {{y}} = ?
        <div >
            <input type="number" [(ngModel)]="inputCaptcha">
        </div>
    </div>

    <div class="flex mx-auto text-center">
        <div class="m-4">
            <p-button [disabled]="inputCaptcha !== sum"
                      (click)="onRejectAssessmentByOperator()"
                      severity="danger" label="{{ 'Confirm' | translate }}"></p-button>
        </div>
        <div class="m-4">
            <p-button (click)="isOpenRejectByOperatorModal = false; isRejectByOperatorButtonDisabled = true; inputCaptcha = 0"
                      severity="info" label="{{ 'Cancel' | translate }}"></p-button>
        </div>
    </div>
</p-dialog>
