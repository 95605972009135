import { Injectable } from '@angular/core';
import { apiRoutes } from '../config/api.routes';
import {HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { TokenServiceService } from './token-service.service';



@Injectable({
    providedIn: 'root'
  })


  export class MainService {

    constructor(
      private http: HttpClient,
      private api: apiRoutes,
      private tokenService: TokenServiceService) {}

  getLocalHost(){
      return this.http.get('http://localhost:8080');
  }

      exit(){
        return this.http.post(this.api.backend + '/auth/logout', {}, this.tokenService.getHeaderAuth());
       }

       authenticate(user: any){
        return this.http.post(this.api.backend + '/auth', user, {headers: {userInfoToken: '' + localStorage.getItem('userInfoToken')}});
      }

      usersList(){
        return this.http.get(this.api.backend + '/users/roles', this.tokenService.getHeaderAuth());
      }

      getAllQuestions(expertId?: number, ) {
        let params = new HttpParams();

        if (expertId !== undefined && expertId !== null) {
            params = params.append('expertId', expertId);
        }

        return this.http.get(this.api.backend + '/questions', {
            params: params,
            headers: this.tokenService.getHttpHeader()
        });
      }

      dateQuestions(fromDate: any, toDate: any){
        return this.http.get(this.api.backend + '/questions' + '?fromDate=' + fromDate + '&toDate=' + toDate, this.tokenService.getHeaderAuth());
      }

      getQuestionsWithFilter(expertId?: number, assessmentStatusTypeId?: number, subjectId?: number, fromDate?: any, toDate?: any) {
        let requestParams = new HttpParams();
        if (expertId !== undefined && expertId !== null) {
            requestParams = requestParams.append("expertId", expertId);
        }
        if (assessmentStatusTypeId !== undefined && assessmentStatusTypeId !== null) {
            requestParams = requestParams.append("assessmentStatusTypeId", assessmentStatusTypeId);
        }
        if (subjectId !== undefined && subjectId !== null) {
            requestParams = requestParams.append("subjectId", subjectId);
        }
        if (fromDate !== undefined && fromDate !== null) {
            requestParams = requestParams.append("fromDate", fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            requestParams = requestParams.append("toDate", toDate);
        }
        return this.http.get(this.api.backend + '/questions', {
            params: requestParams,
            headers: this.tokenService.getHttpHeader()
        });
      }

      getQuestion(id: number) {
        return this.http.get(this.api.backend + '/questions/' + id, this.tokenService.getHeaderAuth());
      }

      getQuestionCriterion(testGradeId: number, expertId: number) {
        return this.http.get(
            this.api.backend + `/assessments/criterion/${testGradeId}/${expertId}`,
            this.tokenService.getHeaderAuth());
      }

      startAssessment(testGrade: number) {
        return this.http.put(this.api.backend + '/assessments/start/work/' + testGrade, null, this.tokenService.getHeaderAuth());
      }

      saveCriterion(data: {criterionId: number, expertId: number, score: number, scoreDescription: string}) {
        return this.http.put(this.api.backend + '/assessments/criterion/grade', data, this.tokenService.getHeaderAuth());
      }

      saveExpertCorrection(data: {testGradeId: number, expertId: number, correction: string}) {
        return this.http.put(this.api.backend + '/assessments/correction', data, this.tokenService.getHeaderAuth());
      }

      savePrevTestGrade(testGradeId: number, expertId: number) {
        return this.http.put(this.api.backend + `/assessments/grade/prev/${testGradeId}/${expertId}`,
            null, this.tokenService.getHeaderAuth());
      }

      confirmAssessmentByExpert(testGradeId: number, expertId: number) {
          return this.http.put(this.api.backend + `/assessments/grade/confirm/by/expert/${testGradeId}/${expertId}`,
              null, this.tokenService.getHeaderAuth());
      }

      confirmAssessmentByOperator(data: {testGradeIds: number[], operatorId: number}) {
        return this.http.put(
            this.api.backend + '/questions/confirm/operator',
            data,
            this.tokenService.getHeaderAuth());
      }

       rejectAssessmentByOperator(data: {testGradeIds: number[]}) {
        return this.http.put(
            this.api.backend + '/questions/revision/expert',
            data,
            this.tokenService.getHeaderAuth());
       }

      block(user: any){
        return this.http.put(this.api.backend + '/users/block', user, this.tokenService.getHeaderAuth());
      }

      activate(user: any){
        return this.http.put(this.api.backend + '/users/activate', user, this.tokenService.getHeaderAuth());
      }

      changePassword(user: any){
        return this.http.put(this.api.backend + '/users/password', user, this.tokenService.getHeaderAuth());
      }

      personalData(iin: any){
        return this.http.get(this.api.backend + '/api/v1/personalData' + '/' + iin, this.tokenService.getHeaderAuth());
      }

      purposeFormation(){
        return this.http.get(this.api.backend + '/api/v1/ref/purposeFormation', this.tokenService.getHeaderAuth());
      }

      olimpSubCategory(purposeFormationId: any, olimpCategoryId: any){
        return this.http.get(this.api.backend + '/api/v1/ref/olimpSubCategory' + '/' + purposeFormationId + '/' + olimpCategoryId, this.tokenService.getHeaderAuth());
      }

      olimpCategory(){
        return this.http.get(this.api.backend + '/api/v1/ref/olimpCategoryIds', this.tokenService.getHeaderAuth());
      }

      olimpiad(purposeFormationId: any, olimpTypeId: any){
        return this.http.get(this.api.backend + '/api/v1/ref/olimpiad' + '/' + purposeFormationId + '/' + olimpTypeId, this.tokenService.getHeaderAuth());
      }

      olimpiadArea(olimpiadId: any){
        return this.http.get(this.api.backend + '/api/v1/ref/olimpiadArea' + '/' + olimpiadId, this.tokenService.getHeaderAuth());
      }

      instituteEduProgram(eduProgramId: any, purposeFormationId: any){
        return this.http.get(this.api.backend + '/api/v1/ref/instituteEduProgram' + '/' + eduProgramId + '/' + purposeFormationId, this.tokenService.getHeaderAuth());
      }

      olimpPrivileges(olimpSubjectId: any, purposeFormationId: any){
        return this.http.get(this.api.backend + '/api/v1/ref/olimpPrivileges' + '/' + olimpSubjectId + '/' + purposeFormationId, this.tokenService.getHeaderAuth());
      }

      addOlimpWinner(value: any){
        return this.http.post(this.api.backend + '/api/v1/addOlimpWinner', value, this.tokenService.getHeaderAuth());
      }

      olimpWinnerSearchByIin(iin: any){
        return this.http.get(this.api.backend + '/api/v1/olimpWinnerSearchByIin' + '/' + iin, this.tokenService.getHeaderAuth());
      }

      olimpWinnerList(){
        return this.http.get(this.api.backend + '/api/v1/olimpWinnerList', this.tokenService.getHeaderAuth());
      }

      olimpWinnerDeleteById(id: any){
        return this.http.delete(this.api.backend + '/api/v1/olimpWinnerById' + '/' + id, this.tokenService.getHeaderAuth());
      }

      olimpWinnerFindById(olimpWinnerId: any){
        return this.http.get(this.api.backend + '/api/v1/olimpWinnerFindById' + '/' + olimpWinnerId, this.tokenService.getHeaderAuth());
      }

      personalDataParent(parentIin: any){
        return this.http.get(this.api.backend + '/api/v1/personalData2' + '/' + parentIin, this.tokenService.getHeaderAuth());
      }

      militaryStatus(){
        return this.http.get(this.api.backend + '/api/v1/ref/militaryStatus', this.tokenService.getHeaderAuth());
      }

      authority(){
        return this.http.get(this.api.backend + '/api/v1/ref/authority', this.tokenService.getHeaderAuth());
      }

      documentType(){
        return this.http.get(this.api.backend + '/api/v1/ref/documentType', this.tokenService.getHeaderAuth());
      }

      reasonMilitaryGrant(){
        return this.http.get(this.api.backend + '/api/v1/ref/reasonMilitaryGrant', this.tokenService.getHeaderAuth());
      }

      eduPrograms(){
        return this.http.get(this.api.backend + '/api/v1/ref/eduPrograms', this.tokenService.getHeaderAuth());
      }

      mcInstituteEduProgram(eduProgramId: any){
        return this.http.get(this.api.backend + '/api/v1/ref/mcInstituteEduProgram' + '/' + eduProgramId, this.tokenService.getHeaderAuth());
      }

      addMilitaryChildren(value: any){
        return this.http.post(this.api.backend + '/api/v1/addMilitaryChildren', value, this.tokenService.getHeaderAuth());
      }

      myProfile(){
        return this.http.get(this.api.backend + '/api/v1/myProfile', this.tokenService.getHeaderAuth());
      }

      editOlimpWinner(olimpWinnerId: any, value: any){
        return this.http.put(this.api.backend + '/api/v1/editOlimpWinner' + '/' + olimpWinnerId, value, this.tokenService.getHeaderAuth());
      }

      militaryChildrenList(){
        return this.http.get(this.api.backend + '/api/v1/militaryChildrenList', this.tokenService.getHeaderAuth());
      }

      militaryChildrenById(id: any){
        return this.http.delete(this.api.backend + '/api/v1/militaryChildrenById' + '/' + id, this.tokenService.getHeaderAuth());
      }

      militaryChildrenFindById(militaryChildrenId: any){
        return this.http.get(this.api.backend + '/api/v1/militaryChildrenFindById' + '/' + militaryChildrenId, this.tokenService.getHeaderAuth());
      }

      editMilitaryChildrenData(militaryChildrenId: any, value: any){
        return this.http.put(this.api.backend + '/api/v1/editMilitaryChildrenData' + '/' + militaryChildrenId, value, this.tokenService.getHeaderAuth());
      }

      workingCategoryIds(authorityId: any){
        return this.http.get(this.api.backend + '/api/v1/ref/workingCategoryIds' + '/' + authorityId, this.tokenService.getHeaderAuth());
      }

      addUser(value: any){
        return this.http.post(this.api.backend + '/users', value, this.tokenService.getHeaderAuth());
      }

      userFunctionsAuthorityList(){
        return this.http.get(this.api.backend + '/users', this.tokenService.getHeaderAuth());
      }

      filterAll(roleId: any, statusIds: any, iin: any){
        return this.http.get(this.api.backend + '/users' + '?roleId=' + roleId + '&statusIds=' + statusIds + '&iin=' + iin, this.tokenService.getHeaderAuth());
      }

      usersRoles(){
        return this.http.get(this.api.backend + '/users/roles', this.tokenService.getHeaderAuth());
      }


      usersResetPassword(user: any){
        return this.http.put(this.api.backend + '/users/reset/password', user, this.tokenService.getHeaderAuth());
      }

      usersPermissions(userId: any){
        return this.http.get(this.api.backend + '/users/permissions' + '/' + userId, this.tokenService.getHeaderAuth());
      }

      usersPermissionsTypes(userId: any){
        return this.http.get(this.api.backend + '/users/permissions/types' + '/' + userId, this.tokenService.getHeaderAuth());
      }

      usersSetPermissions(user: any){
        return this.http.put(this.api.backend + '/users/set/permissions', user, this.tokenService.getHeaderAuth());
      }

      usersSetAdmins(user: any){
        return this.http.put(this.api.backend + '/users/set/admins', user, this.tokenService.getHeaderAuth());
      }

      usersSetOperators(user: any){
        return this.http.put(this.api.backend + '/users/set/operators', user, this.tokenService.getHeaderAuth());
      }

      usersSetExperts(user: any){
        return this.http.put(this.api.backend + '/users/set/experts', user, this.tokenService.getHeaderAuth());
      }

      expertsQuestionsTypes(expertId: any){
        return this.http.get(this.api.backend + '/experts/questions/types' + '/' + expertId, this.tokenService.getHeaderAuth());
      }

      setExpertQuestionType(data: {expertId:number,subjectId:number,questionTypeId:number}) {
        return this.http.post(this.api.backend + '/experts/questions/types', data, this.tokenService.getHeaderAuth());
      }

      deleteExpertQuestionType(data: {expertId:number,subjectId:number,questionTypeId:number}) {
          return this.http.delete(this.api.backend + '/experts/questions/types',
              {headers: this.tokenService.getHttpHeader(), body:data});
      }

      questionsSubjectsTypes(){
        return this.http.get(this.api.backend + '/questions/subjects/types', this.tokenService.getHeaderAuth());
      }

      questionTypes() {
        return this.http.get(this.api.backend + '/questions/types', this.tokenService.getHeaderAuth());
      }

      questionsSetExpert(data: any){
        return this.http.post(this.api.backend + '/questions/set/expert', data, this.tokenService.getHeaderAuth());
      }

      getUsers(roleId: any, questionTypeIds: any){
        return this.http.get(this.api.backend + '/users' + '?roleId=' + roleId + '&questionTypeIds=' + questionTypeIds, this.tokenService.getHeaderAuth());
      }

      questionsSubjectsTypesFilter(subjectId: any){
        return this.http.get(this.api.backend + '/questions' + '?subjectId=' + subjectId, this.tokenService.getHeaderAuth());
      }

      assessmentStatusFilter(assessmentStatusTypeId: any){
        return this.http.get(this.api.backend + '/questions' + '?assessmentStatusTypeId=' + assessmentStatusTypeId, this.tokenService.getHeaderAuth());
      }

      assessmentsStatuses(){
        return this.http.get(this.api.backend + '/assessments/statuses', this.tokenService.getHeaderAuth());
      }

      user(){
        return this.http.get(this.api.backend + '/users', this.tokenService.getHeaderAuth());
      }

      userFilter(expertId: any){
        return this.http.get(this.api.backend + '/questions' + '?expertId=' + expertId, this.tokenService.getHeaderAuth());
      }



}

