<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <form [formGroup]="form">
        <div class="flex flex-column align-items-center justify-content-center">
            <div
                style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                    <div class="text-center mb-5">
                        <img src="../assets/logo-wo-words.png" alt="Image" height="50" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3"> {{ 'Auth' | translate }} </div>
                        <span class="text-600 font-medium"> {{ 'LoginAndPass' | translate }} </span>
                    </div>
                    <div>
                        <span class="p-float-label mt-4">
                            <input formControlName="login" id="email1" type="text" pInputText
                                class="w-full md:w-30rem">
                            <label class="block text-900 font-medium"> {{ 'Login' | translate }} </label>
                        </span>
                        <span class="p-float-label mt-4">
                            <p-password (keydown.enter)="auth()" type="password" [feedback]="false"
                                formControlName="password" [toggleMask]="true"
                                inputStyleClass="w-full md:w-30rem"></p-password>
                            <label for="password" class="block text-900 font-medium"> {{ 'Password' | translate }}
                            </label>
                        </span>
                        <button (click)="auth()" pButton pRipple label="{{ 'LoginIn' | translate }}"
                            style="border-color: #2563eb; background-color: #2463eb;"
                            class="w-full p-3 mt-4 text-xl"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>