<div class="grid p-fluid">
	<div class="col-12 md:col-11">
		<div class="card">
			<p-dialog [modal]="true" header="{{ 'ChangePasswordUser' | translate }}" [(visible)]="changePassVisible"
				[style]="{width: '40vw', height: '15vw'}">
				<div class="text-center">
					<span class="p-float-label my-6">
						<input [(ngModel)]="newPassword" [style]="{'width': '60%'}" pInputText>
						<label [style]="{'width': '45%'}" htmlFor="username"> {{ 'Password' | translate }} </label>
					</span>
					<p-button [style]="{'width': '60%'}" class="text-center" (onClick)="changePassword()"> {{ 'ChangePassword' | translate }} </p-button>
				</div>
			</p-dialog>
			<p-dialog header="{{'AssignmentRights' | translate }}" [(visible)]="visibleFil" [style]="{width: '50vw', height: '30vw'}">
				<p-table [value]="usersPermissionsList" [(selection)]="usersPermissionsSelected" dataKey="id"
					[tableStyle]="{'min-width': '50rem'}">
					<ng-template pTemplate="header">
						<tr>
							<th style="width: 4rem">
								<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
							</th>
							<th>{{ 'NamesOfRights' | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-role>
						<tr>
							<td>
								<p-tableCheckbox [value]="role"></p-tableCheckbox>
							</td>
							<td>
								<span *ngIf="translate.currentLang == 'kz'"> {{ role.nameKaz }} </span>
                                <span *ngIf="translate.currentLang == 'ru'"> {{ role.nameRu }} </span>
							</td>
						</tr>
					</ng-template>
				</p-table>
					<div class="text-center my-4">
						<p-button (onClick)="usersSetPermissions()" [style]="{'width': '50%'}" class="text-center">
							{{ 'Apply' | translate }} </p-button>

					</div>
					<div class="text-center my-4">
							<p-button severity="danger" (onClick)="visibleFil = false" [style]="{'width': '50%'}" class="text-center">
								{{ 'Cancel' | translate }} </p-button>
					</div>

			</p-dialog>

			<p-dialog header="{{ 'AssigningJobTypes' | translate }}"
                      [modal]="true"
                      [(visible)]="visible" [style]="{width: '35vw', height: '30vw'}">
                <ul class="subject-list">
                    <li *ngFor="let subject of allSubjectTypes" class="subject-item">
                        <div class="subject-name text-lg">
							<span *ngIf="translate.currentLang === 'kz'"> {{ subject.nameKaz }} </span>
                            <span *ngIf="translate.currentLang === 'ru'"> {{ subject.nameRu }} </span>
                        </div>
                        <ul class="question-types-list">
                            <li *ngFor="let type of allQuestionTypes" class="question-type-item hover:bg-gray-300">
								<span *ngIf="translate.currentLang === 'kz'"> {{ type.nameKaz }} </span>
                            	<span *ngIf="translate.currentLang === 'ru'"> {{ type.nameRu }} </span>
                                <button
                                    (click)="saveExpertQuestionType(clickedId, subject.id, type.id)"
                                    *ngIf="!isExpertHasSubjectQuestionType(clickedId, subject.id, type.id)"  class="add-button" >
                                    {{ 'Add' | translate}}
                                </button>
                                <button
                                    (click)="deleteExpertQuestionType(clickedId, subject.id, type.id)"
                                    *ngIf="isExpertHasSubjectQuestionType(clickedId, subject.id, type.id)"  class="remove-button" >
                                    {{ 'PutAway' | translate }}
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>

			</p-dialog>
			<div class="my-3">
				<div class="mt-5 flex justify-content-center">
					<div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
						style="background-color: #3B82F6; height: 3px;"></div>
					<div
						class="text-2xl justify-content-center mx-5 flex align-items-center justify-content-center border-round uppercase">
						{{ 'DATAOFADMINISTRATORSBYEVENTS' | translate }} </div>
					<div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
						style="background-color: #3B82F6; height: 3px;"></div>
				</div>
				<div class="">
					<div style="width: 50%;">
						<div class="mt-3" style="width: 50%;">
							<p-button class="mr-2" [routerLink]="'/add-user'" [style]="{'width':'50%'}"
								icon="pi pi-user-plus" label="{{ 'AddOper' | translate }}"></p-button>
							<!-- <p-button class="mr-2" (onClick)="showDialogFil()" [style]="{'width':'40%'}" icon="pi pi-cog"
								label="{{ 'Фильтры' | translate }}"></p-button> -->
						</div>
					</div>
				</div>
			</div>
			<p-table #dt1 dataKey="id" [(selection)]="selectedUsers" [value]="userList" [paginator]="true" [rows]="10"
				[showCurrentPageReport]="true" [tableStyle]="{ 'width': '100%', 'margin-top': '25px'}"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
				[rowsPerPageOptions]="[10, 25, 50]"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
				[globalFilterFields]="['iin', 'username', 'roleName', 'statusName']"
				[tableStyle]="{ 'min-width': '75rem' }">
				<ng-template pTemplate="caption">
					<div class="flex">
						<span class="p-input-icon-left ml-auto">
							<i class="pi pi-search"></i>
							<input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')"
								placeholder="{{ 'Filter' | translate }}" />
						</span>
					</div>
					<div class="mt-3">
						<p-button (onClick)="block()" severity="danger" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-lock"
							label="{{ 'block' | translate }}" size="small"></p-button>
						<p-button severity="success" (onClick)="activate()" class="mr-2" [style]="{'width':'15%'}" icon="pi pi-lock-open"
							label="{{ 'unBlock' | translate }}" size="small"></p-button>
						<p-button severity="warning" (onClick)="usersResetPassword()" class="mr-2" [style]="{'width':'15%'}"
							icon="pi pi-asterisk" label="{{ 'ResetThePassword' | translate }}" size="small"></p-button>
						<p-button *ngIf="userRole == '1'" (onClick)="usersSetAdmins()" class="mr-2"
							[style]="{'width':'20%'}" icon="pi pi-asterisk"
							label="{{ 'AssignAsAdministrator' | translate }}" size="small"></p-button>
						<p-button *ngIf="userRole == '1'" (onClick)="usersSetOperators()" class="mr-2" [style]="{'width':'15%'}"
							icon="pi pi-asterisk" label="{{ 'AssignAsOperator' | translate }}"
							size="small"></p-button>
						<p-button *ngIf="userRole == '1'" (onClick)="usersSetExperts()" class="mr-2" [style]="{'width':'15%'}"
							icon="pi pi-asterisk" label="{{ 'AppointAsAnExpert' | translate }}"
							size="small"></p-button>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="width:5%"> {{ '' | translate }} <p-tableHeaderCheckbox></p-tableHeaderCheckbox> </th>
						<th style="width:25%" pSortableColumn="iin"> {{ 'iin' | translate }}
                            <p-sortIcon field="iin"></p-sortIcon>
                        </th>
						<th style="width:25%" pSortableColumn="username"> {{ 'FIO' | translate }}
                            <p-sortIcon field="username"></p-sortIcon>
                        </th>
						<th style="width:25%" pSortableColumn="username"> {{ 'Логин' | translate }}
                            <p-sortIcon field="username"></p-sortIcon>
						</th>
						<th style="width:25%" pSortableColumn="roleName"> {{ 'role' | translate }}
                            <p-sortIcon field="roleName"></p-sortIcon>
                        </th>
						<th style="width:25%" pSortableColumn="statusName"> {{ 'status' | translate }}
                            <p-sortIcon field="statusName"></p-sortIcon>
                        </th>
						<th class="text-center" style="width:30%"> {{ 'ACTIONS' | translate }} </th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-customer>
					<tr>
						<td>
							<p-tableCheckbox [value]="customer"></p-tableCheckbox>
						</td>
						<td>
							<span> {{ customer.iin }} </span>
						</td>
						<td>
							<span> {{ customer.username }} </span>
						</td>
						<td>
							<span> {{ customer.login }} </span>
						</td>
						<td>
							<span *ngIf="translate.currentLang == 'kz'"> {{ customer.roleNameKaz }} </span>
                            <span *ngIf="translate.currentLang == 'ru'"> {{ customer.roleNameRu }} </span>
						</td>
						<td>
							<span *ngIf="translate.currentLang == 'kz'"> {{ customer.statusNameKaz }} </span>
                            <span *ngIf="translate.currentLang == 'ru'"> {{ customer.statusNameRu }} </span>
						</td>
						<td>
							<div class="flex text-center">
								<div class="mr-1" style="width: auto;">
									<p-button (onClick)="showDialogFil(customer.id, customer.roleId)"
										icon="pi pi-asterisk" label="{{ 'AssignRights' | translate }}" size="small"></p-button>
								</div>
								<div style="width: 15rem;">
									<p-button (onClick)="showDialog(customer.id)"
										icon="pi pi-asterisk" label="{{ 'AssignJobTypes' | translate }}" size="small"></p-button>
								</div>
							</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="paginatorleft">
				</ng-template>
				<ng-template pTemplate="paginatorright">
				</ng-template>
			</p-table>
		</div>
	</div>
</div>
