<button class="layout-config-button p-link" type="button" (click)="onConfigButtonClick()">
    <i class="pi pi-cog"></i>
</button>

<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-config-sidebar w-20rem">
    <!-- <select  class="" #langSelect (change)="changeLang(langSelect.value)">
        <option [value]="'ru'" [selected]="translate.currentLang == 'ru'">ru</option>
        <option [value]="'kz'" [selected]="translate.currentLang == 'kz'">kz</option>
    </select> -->
    <H5 class="mt-5">Размер интерфейса</H5>

    <div class="flex align-items-center">
        <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()"
            class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
        <div class="flex gap-2 align-items-center">
            <i class="pi pi-circle-fill text-300" *ngFor="let s of scales"
                [ngClass]="{'text-primary-500': s === scale}"></i>
        </div>
        <button icon="pi pi-plus" type="button" pButton (click)="incrementScale()"
            class="p-button-text p-button-rounded w-2rem h-2rem ml-2"
            [disabled]="scale === scales[scales.length - 1]"></button>
    </div>
    <H5 class="mt-5">Язык</H5>
    <div class="flex mt-3 align-items-center">
        <!-- <label for="ru"> <b>Русский</b> </label>
        <p-radioButton #langSelect class="mx-2" name="ru" [value]="'ru'" [(ngModel)]="translate.currentLang"
            inputId="ru" (change)="changeLang(langSelect.value)"></p-radioButton>
        <label for="kz"> <b>Казахский</b> </label>
        <p-radioButton #langSelect class="mx-2" name="kz" [value]="'kz'" [(ngModel)]="translate.currentLang"
            inputId="kz" (change)="changeLang(langSelect.value)"></p-radioButton> -->
    <p-dropdown #langSelect  [options]="langs" [(ngModel)]="lang" optionLabel="label" (onChange)="changeLang(lang.value)"></p-dropdown>
    </div>
    <h5 class="mt-5">Тема</h5>
    <div class="align-content-center mt-3 grid">
        <div class="col-3">
            <p-button icon="pi pi-sun" class="p-link w-4rem h-2rem" (click)="changeTheme('lara-light-blue', 'light')">
            </p-button>
        </div>
        <div class="col-3">
            <p-button icon="pi pi-moon" class="p-link w-4rem h-2rem" (click)="changeTheme('lara-dark-blue', 'dark')">
            </p-button>
        </div>
    </div>

</p-sidebar>