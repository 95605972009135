import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { MainInfoComponent } from './main-info/main-info.component';
import { UsersComponent } from './users/users.component';
import { LoginComponent } from './login/login.component';
import { AddUserComponent } from './add-user/add-user.component';
import {QuestionsComponent} from "./general/questions/questions.component";
import {QuestionComponent} from "./general/questions/question/question.component";
import { ConnectComponent } from './connect/connect.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: '', component: AppLayoutComponent, children: [
                    { path: '', component: MainInfoComponent },
                    { path: 'users', component: UsersComponent },
                    { path: 'add-user', component: AddUserComponent },
                    { path: 'questions', component: QuestionsComponent },
                    { path: 'questions/:id', component: QuestionComponent }
                ]},
            { path: 'login',  component: LoginComponent },
            { path: 'connect',  component: ConnectComponent },

        ])
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
