import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { MainService } from '../services/main.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { SlideMenuModule } from 'primeng/slidemenu';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-main-info',
  templateUrl: './main-info.component.html',
  styleUrls: ['./main-info.component.scss']

})
export class MainInfoComponent {
  
  items!: MenuItem[];
  visible: boolean = false;
  newPassword: any;
  userId: any = localStorage.getItem('userId');
  userStatusId: any = localStorage.getItem('userStatusId');
  repitPassword: any;
  isPassSub: any;
  isPassMatch: any;


  constructor(public layoutService: LayoutService,
    private primengConfig: PrimeNGConfig,
    public slideMenuModule: SlideMenuModule,
    private route: ActivatedRoute,
    private main: MainService,
    public router: Router,
    public translate: TranslateService,
    public reactiveFormsModule: ReactiveFormsModule,
    ) {

  }

  ngOnInit() {
    this.primengConfig.ripple = true;
      if(localStorage.getItem('userStatusId') == '3'){
        this.showDialog()

      }

    }

    showDialog() {
      this.visible = true;
  }

  changePassword(){
    this.isPassSub = true
    let user = {}
    user = {
      newPassword: this.newPassword,
      userId: this.userId

    }
    if(this.isPasswordmatch()){
      this.main.changePassword(user)
    .subscribe({
      next: (res: any) => {
      this.visible = false;
      this.logOut()

      }
    })
    }
  }

  isPasswordmatch(){
    this.isPassMatch = this.newPassword === this.repitPassword
    return this.isPassMatch
  }

  logOut() {
    this.main.exit()
        .subscribe((res: any) => {
            localStorage.clear();
            window.location.href = './login';
        }, (err: any) => {
        });
}
}


