import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MainService } from '../services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TokenServiceService } from '../services/token-service.service';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    form!: FormGroup;
    token: any;
    items: any = [];
    roleId:number  = Number(localStorage.getItem('roleId'));
    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        public main: MainService,
        public router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private tokens: TokenServiceService,

    ) {
        translate.addLangs(['ru', 'kz']);
        translate.setDefaultLang('ru');
        const browserLang: any = translate.getBrowserLang();
        translate.use(browserLang.match(/ru|kz/) ? browserLang : 'ru');
        this.changeLang(localStorage.getItem('Lang'));
    }

    ngOnInit() {

        this.roleId = Number(localStorage.getItem('roleId'));

            this.model = [
                {
                    items: [
                        { label: 'questions', icon: 'pi pi-fw pi-list', routerLink: ['/questions'] },
                    ]
                },
            ];

        if (this.roleId != null && this.roleId != 3) {
            this.model[0].items.push(
                { label: 'users', icon: 'pi pi-fw pi-users', routerLink: ['/users'] }
            );
        }
        // this.model[0].items.push({ label: 'info', icon: 'pi pi-fw pi-info', routerLink: ['/'] });
        this.model[0].items.push({ label: 'exit', icon: 'pi pi-fw pi-sign-out', command: () => { this.logOut() } });
    }




    changeLang(lang: any) {
        this.translate.use(lang);
        localStorage.setItem('Lang', lang);
    }

    logOut() {
        this.main.exit()
            .subscribe((res: any) => {
                localStorage.clear();
                window.location.href = './login';
            }, (err: any) => {
                localStorage.clear();
                window.location.href = './login';
            });
    }
}
