<div class="card justify-content-center">
    <div class="mt-5 flex justify-content-center">
        <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
            style="background-color: #3B82F6; height: 3px;"></div>
        <div 
            class="text-2xl justify-content-center mx-5 flex align-items-center justify-content-center border-round uppercase">
            {{ 'editUser' | translate }} </div>
        <div class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center border-round mt-3 mx-5"
            style="background-color: #3B82F6; height: 3px;"></div>
    </div>
    <div style="margin-left: 25%;">
        <div class="mt-3">
            <span class="p-float-label mt-4">
                <input [(ngModel)]="username" [style]="{'width': '60%'}" pInputText>
                <label htmlFor="username"> {{ 'FIO' | translate }} </label>
            </span>
        </div>
    </div>
    <div style="margin-left: 25%;">
        <div class="mt-3">
            <span class="p-float-label mt-4">
                <input [(ngModel)]="iin" [style]="{'width': '60%'}" pInputText>
                <label htmlFor="username"> {{ 'iin' | translate }} </label>
            </span>
        </div>
    </div>
    <div style="margin-left: 25%;">
        <div class="mt-3">
            <span class="p-float-label mt-4">
                <input [(ngModel)]="phoneNumber" [style]="{'width': '60%'}" pInputText>
                <label htmlFor="username"> {{ 'phoneNumber' | translate }} </label>
            </span>
        </div>
    </div>
    <span class="p-float-label mt-4" style="margin-left: 25%;">
        <p-dropdown [options]="userRoleData" [(ngModel)]="selectedRole" [style]="{'width': '60%'}"
            placeholder="{{ 'Выбор роли' | translate }}" optionLabel="nameRus" inputId="float-label">
            <ng-template let-item pTemplate="student">
                <span> {{ item.nameRus }} </span>
            </ng-template>
        </p-dropdown>
        <label style="width: 60%;" for="float-label"> {{ 'Выбор роли' | translate }} </label>
    </span>
    <div class="p-buttonset" style="margin-left: 25%; margin-top: 15px;">
        <p-button (onClick)="addUser()" icon="pi pi-check" styleClass="border-round-left" type="button"
            [style]="{'width': '30%'}" label="{{ 'Add' | translate }}"></p-button>
        <p-button [routerLink]="'/users'" iconPos="right" icon="pi pi-times"
            styleClass="p-button-danger border-round-right" type="button" [style]="{'width': '30%'}"
            label="{{ 'Cancel' | translate }}"></p-button>
    </div>