import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TokenServiceService {
  constructor() { }

  authToken(token: string): boolean {
    localStorage.setItem('token', 'token');
    return true;
  }

  getTok(): any {
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
     }
     return headers;
  }

  getHeaderAuth(): any {
    if (localStorage.getItem('token')) {
      let headers = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
      })
      }
      return headers;
    }
    return this.getHeaderNotAuth();
  }

    getHttpHeader(): HttpHeaders {
        if(localStorage.getItem('token')){
            let headers = new HttpHeaders()
                .set('Authorization', 'Bearer ' + localStorage.getItem('token'));
            return headers;
        }
        return new HttpHeaders();
    }


  getHeaderNotAuth(): any {
    return {};
  }
}
