import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { MainService } from '../services/main.service';
import { TokenServiceService } from '../services/token-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  form!: FormGroup;
  message: any;
  login: any;
  password: any;

  constructor(
    private primengConfig: PrimeNGConfig,
    public route: ActivatedRoute,
    public router: Router,
    public main: MainService,
    public token: TokenServiceService
  ) {

  }

  ngOnInit() {

    this.form = new FormGroup({
      password: new FormControl(),
      login: new FormControl(),

    });
    this.primengConfig.ripple = true;
    this.form.get('login')!.setValue(this.login);
    this.form.get('password')!.setValue(this.password);

    this.getLocal();
    this.operatorToken();

    let tok: any = localStorage.getItem('token');

  }

  getLocal(){
    this.main.getLocalHost()
    .subscribe((res: any) => {
      localStorage.setItem('userInfoToken', res.token);
    }, (err: any) => {
        this.router.navigate(['/connect']);
    });
  }

  operatorToken(){
    if(this.route.snapshot.queryParamMap.get('token') != null) {
      if(localStorage.getItem('token') == null){
        this.main.getLocalHost()
    .subscribe((res: any) => {
      this.main.authenticate(this.route.snapshot.queryParamMap.get('token')!)
      .subscribe((res: any) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('authToken', this.route.snapshot.queryParamMap.get('token')!);

        // this.permissions = localStorage.getItem('permissions');
        // this.username = localStorage.getItem('username');
        // this.testOrgId = localStorage.getItem('testOrgId');
        // this.testOrgName = localStorage.getItem('testOrgName');
        window.location.reload();
      }, (err: any) => {
        // this.router.navigate(['https://auth.testcenter.kz/auth?link=https:%2F%2Fent-operator.testcenter.kz%2Fmain']);

    });
    }, (err: any) => {
        this.router.navigate(['/app-nct']);

    });
    }
    }else{
      if(localStorage.getItem('token') == null){
      }
    }
  }

  auth() {
    this.main.authenticate(this.form.value)
      .subscribe((res: any) => {
        this.router.navigate(['/questions']);
        localStorage.setItem('token', res.token);
        localStorage.setItem('roleId', res.roleId);
        localStorage.setItem('userStatusId', res.userStatusId);
        localStorage.setItem('userId', res.userId);
        localStorage.setItem('roleCode', res.roleCode);
        localStorage.setItem('roleNameKaz', res.roleNameKaz);
        localStorage.setItem('Lang', 'kz');
      }, (err: any) => {

      });
  }

}
