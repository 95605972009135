import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ReactiveFormsModule } from '@angular/forms';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent {

  constructor(
    private primengConfig: PrimeNGConfig,
    public slideMenuModule: SlideMenuModule,
    private route: ActivatedRoute,
    private main: MainService,
    public router: Router,
    public translate: TranslateService,
    public reactiveFormsModule: ReactiveFormsModule){}

    ref = `https://auth.testcenter.kz/auth?link=` + window.location.href;


  ngOnInit(): void {
  }

  CheckConnection(){
    this.main.getLocalHost()
    .subscribe((res: any) => {
      localStorage.setItem('userInfoToken', res.token);
      this.router.navigate(['/login']);
    }, (err: any) => {
      
    });
  }

  back(){
    localStorage.clear();
    window.location.href = './';
  }

}
