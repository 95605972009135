import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { Routes,RouterModule } from '@angular/router';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { apiRoutes } from './config/api.routes';
import { PhotoService } from './demo/service/photo.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { ChipsModule } from "primeng/chips";
import { DropdownModule } from "primeng/dropdown";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputTextModule } from "primeng/inputtext";
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AddUserComponent } from './add-user/add-user.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MainInfoComponent } from './main-info/main-info.component';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SliderModule } from 'primeng/slider';
import { UsersComponent } from './users/users.component';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import {QuestionsModule} from "./general/questions/questions.module";
import { TreeTableModule } from 'primeng/treetable';
import { TreeModule } from 'primeng/tree';
import { ConnectComponent } from './connect/connect.component';
import { CardModule } from 'primeng/card';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeRu);
// const routes: Routes =[
    // { path: '', redirectTo: '/auth-page', pathMatch: 'full' },
    // { path: 'main', component: MainComponent, children: [
    //   { path: '', component: MainInfoComponent },
    //   { path: 'edit-diplom', component: EditMilDiplomComponent},
    //   ]},

//   ]

@NgModule({
    declarations: [
        AppComponent,
        AddUserComponent,
        LoginComponent,
        MainComponent,
        MainInfoComponent,
        UsersComponent,
        ConnectComponent,
    ],
    imports: [
        AppRoutingModule,
        CardModule,
        ListboxModule,
        ToastModule,
        ConfirmDialogModule,
        AppLayoutModule,
        BrowserModule,
        InputSwitchModule,
        ButtonGroupModule,
        FormsModule,
        SliderModule,
        ButtonModule,
        SplitButtonModule,
        AutoCompleteModule,
        CalendarModule,
        TreeModule,
        TreeTableModule,
        DialogModule,
        PasswordModule,
        SelectButtonModule,
        ChipsModule,
        CheckboxModule,
        DropdownModule,
        InputMaskModule,
        InputNumberModule,
        SlideMenuModule,
        CascadeSelectModule,
        // RouterModule.forRoot(routes),
        MultiSelectModule,
        InputTextareaModule,
        InputTextModule,
        TableModule,
        RadioButtonModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'ru',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        QuestionsModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService,apiRoutes,{ provide: LOCALE_ID, useValue: 'ru' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
