<div class="layout-topbar">
    <a class="layout-topbar-logo">
        <img src="../assets/logo-wo-words.png" alt="logo">
    </a>
    <button #menubutton
            class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <div class="ml-auto">
        <p class="m-4">
            <!-- {{ localStorage.getItem('roleCode') }} -->
            <span *ngIf="translate.currentLang == 'kz'"> {{ localStorage.getItem('roleNameKaz') }}</span>
            <span *ngIf="translate.currentLang == 'ru'"> {{ localStorage.getItem('roleCode') }}</span>
        </p>
    </div>

</div>
