import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ReactiveFormsModule } from '@angular/forms';
import { MainService } from '../services/main.service';

interface data {
  iin?: string;
  username?: string;
  roleId?: string;
  phoneNumber?: any;
  id?: any;
};


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {

  constructor(private primengConfig: PrimeNGConfig,
    public slideMenuModule: SlideMenuModule,
    private route: ActivatedRoute,
    private main: MainService,
    public router: Router,
    public translate: TranslateService,
    public reactiveFormsModule: ReactiveFormsModule) {

  }

  iin: any;
  phoneNumber: any;
  username: any;
  roleId: any;
  currentRoleId = localStorage.getItem('roleId');
  userRoleData: any;
  selectedRole!: data;
  data: data = {};
  id: any;
  userId: any;

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.usersRole();
  }

  usersRole() {
    this.main.usersList()
      .subscribe({
        next: (res: any) => {
          this.userRoleData = res;
          if (this.currentRoleId == '2') {
            this.userRoleData = this.userRoleData.filter((obj: any) => obj.id == 3)
            this.selectedRole = this.userRoleData.find((item: any) => item.id === 3);
          }
        },
        error: (err: any) => {
        }
      });
  }

  addUser() {
    let userData: any = {}
    userData = {
      iin: this.iin,
      phoneNumber: this.phoneNumber,
      username: this.username,
      roleId: this.selectedRole?.id
    }
    this.main.addUser(userData)
      .subscribe({
        next: (res: any) => {
          this.router.navigate(['/users']);

        },
        complete: () => {

        }
      })


  }

}
// if(this.departmentList.length == 0){
//   this.workingCategoryItems.forEach((e: any) => {
//     this.userData.userFunctionsAuthorityDTO.push(
//       {
//         authorityId: this.authorityList.id,
//         departmentId: 0,
//         workingCategoryId: e.id
//       }
//     );
//   });
// }else{
// this.workingCategoryItems.forEach((e: any) => {
//   this.userData.userFunctionsAuthorityDTO.push(
//     {
//       authorityId: this.authorityList.id,
//       departmentId: this.departmentItems.id,
//       workingCategoryId: e.id
//     }
//   );
// });
// }

// this.main.addUser(this.userData)
// .subscribe({
//   next: (res: any) => {
//     this.router.navigate(['/main/otvet-sec']);
//     this.userData.userFunctionsAuthorityDTO = [];
//   },
//   error: (err: any) => {
//     this.userData.userFunctionsAuthorityDTO = [];
//   }
// });