import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MainService} from "../../../services/main.service";
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {Subject, Subscription, takeUntil, timer} from "rxjs";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent  implements OnInit, OnDestroy {

    protected aFormGroup: FormGroup;
    captchaSiteKey: string;
    isCaptchaLoading: boolean = false;

    questionId: number;
    question: any;
    questionContent: any;
    audioServerLink: string = '';
    questionExpertId: number;
    statusId: number;
    expertCorrection: string;
    testScore: number;
    isQuestionBelongsExpert: boolean = false;
    isStartWorkEnable: boolean = false;

    questionCriterionList: any[] = [];
    roleId: number;
    userId: number;

    isCorrectionEditorOpen: boolean = false;
    saveCorrectionButtonLoading: boolean = false;
    savePrevGradeButtonLoading: boolean = false;
    saveCriterionButtonLoading: boolean = false;
    isCriterionValid: boolean = true;
    isExpertConfirmModalOpen: boolean = false;
    isConfirmButtonDisabled: boolean = true;
    isOpenConfirmByOperatorModal: boolean = false;
    isConfirmByOperatorButtonDisabled: boolean = true;
    isOpenRejectByOperatorModal: boolean = false;
    isRejectByOperatorButtonDisabled: boolean = true;

    inputCaptchaSum: number;
    inputCaptcha: number = 0;

    private onDestroy$: Subject<void> = new Subject<void>();
    private saveCriterionTimerSubscription: Subscription;
    private saveCriterionDelay: number = 40; // .04 seconds delay

    private saveCorrectionTimerSubscription: Subscription;
    private saveCorrectionDelay: number = 5000; // 5 seconds delay

    x: number = 0;
    y: number = 0;
    sum: number = 0;

    currentCriterionInfo: any = null;
    isShowCriterionInfo: boolean = false;

    constructor(private activatedRouter: ActivatedRoute,
                private router: Router,
                private main: MainService,
                private sanitizer: DomSanitizer,
                public translate: TranslateService,
                private formBuilder: FormBuilder) {
        this.captchaSiteKey = '6LdUosspAAAAADQa5BhiyinQSKxC8hRI-5az6v9A';
    }

    ngOnInit(): void {
        this.audioServerLink = environment.baseAudioUrl;
        this.initValues();
    }

    initValues(): void{
        this.inputCaptchaSum = 17;
        this.inputCaptcha = 0;
        this.roleId = Number(localStorage.getItem("roleId"));
        this.userId = Number(localStorage.getItem("userId"));
        this.questionId = Number(this.activatedRouter.snapshot.paramMap.get('id'));
        this.initQuestion();
        this.aFormGroup = this.formBuilder.group({
            recaptcha: ['', Validators.required]
        });
    }

    initQuestion() {
        this.main.getQuestion(this.questionId).subscribe(
            (res: any): void => {
                this.question = res;
                this.questionContent = res.questionContent;
                this.questionExpertId = res.expertId;
                this.statusId = res.statusId;
                this.expertCorrection = res.expertCorrection;
                this.testScore = res.testScore;
                if (this.questionExpertId !== undefined && this.questionExpertId !== null && this.questionExpertId !== 0) {
                    this.isQuestionBelongsExpert = this.userId === this.questionExpertId;
                    if (this.statusId === 1 || this.statusId === 5) {
                        this.isStartWorkEnable = true;
                    }
                    this.callGetQuestionCriterion();
                }
            },
            (err: any): void => {
                console.log(err);
            }
        );
    }

    callGetQuestionCriterion() {
        this.main.getQuestionCriterion(this.questionId, this.questionExpertId).subscribe(
            (res: any): void => {
                this.questionCriterionList = res;
            },
            (err: any): void => {
                console.log(err);
            }
        );
    }

    getSanitizedHtml(html?: string): string {
        return <string>this.sanitizer.bypassSecurityTrustHtml(<string>html);
    }

    startAssessing() {
        this.callGetStartAssessment();
    }

    callGetStartAssessment() {
        if (this.statusId == 1 || this.statusId == 3 || this.statusId == 5) {
            this.main.startAssessment(this.questionId).subscribe(
                (res: any) => {
                    this.statusId = 2;
                    this.isStartWorkEnable = false;
                },
                (err: any): void => {
                    console.log(err);
                }
            );
        }
    }



    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        if (this.saveCriterionTimerSubscription) {
            this.saveCriterionTimerSubscription.unsubscribe();
        }
    }

    onClickSaveAllCriterion() {
        this.saveCriterionButtonLoading = true;
        this.questionCriterionList.forEach(obj => {
            this.saveCriterionGrade(obj)
        });
        setTimeout(() => {
            this.saveCriterionButtonLoading = false;
        }, 1000);
    }

    saveCriterionGrade(criterion: any) {
        this.main.saveCriterion({
            criterionId: criterion.id,
            expertId: this.questionExpertId,
            score: criterion.criterionScore,
            scoreDescription: criterion.criterionScoreDescription
        }).subscribe(
            (res: any) => {
                this.callGetQuestionCriterion();
            },
            (err: any) => {
                console.log(err);
                this.saveCriterionButtonLoading = false;
                this.savePrevGradeButtonLoading = false;
            }
        );
    }

    onCorrectionChange(expertCorrection: any) {
        // Save the criterion data
        if (this.saveCorrectionTimerSubscription) {
            this.saveCorrectionTimerSubscription.unsubscribe();
        }
        if (expertCorrection !== null &&
            expertCorrection !== undefined &&
            expertCorrection !== '') {
            this.saveCorrectionTimerSubscription = timer(this.saveCorrectionDelay).pipe(
                takeUntil(this.onDestroy$)
            ).subscribe(() => {
                this.saveExpertCorrection(expertCorrection);
            });
        }
    }

    saveExpertCorrection(expertCorrection: any) {
        this.saveCorrectionButtonLoading = true;
        setTimeout(() => {
            this.main.saveExpertCorrection({
                testGradeId: this.questionId,
                expertId: this.questionExpertId,
                correction: this.expertCorrection
            }).subscribe(
                (res: any) => {
                    this.saveCorrectionButtonLoading = false;
                },
                (err: any) => {
                    this.saveCorrectionButtonLoading = false;
                    console.log(err);
                }
            );
        }, 1500);

    }

    savePrevTestGrade() {
        if(this.statusId === 2 && this.userId === this.questionExpertId) {
            if (this.isCriterionListValid()) {
                this.savePrevGradeButtonLoading = true;
                this.questionCriterionList.forEach(obj => {
                    this.saveCriterionGrade(obj)
                });
                setTimeout(() => {
                    this.main.savePrevTestGrade(this.questionId, this.userId).subscribe(
                        (res: any): void => {
                            this.statusId = 3;
                            this.savePrevGradeButtonLoading = false;
                        },
                        (err: any): void => {
                            console.log(err);
                            this.savePrevGradeButtonLoading = false;
                        }
                    );
                }, 1000);
            }
        }
    }

    isCriterionListValid() {
        let isValid = true;
        this.questionCriterionList.forEach(obj => {
            if (obj.criterionScoreDescription === null ||
                obj.criterionScoreDescription === undefined ||
                obj.criterionScoreDescription.length < 1) {
                isValid = false;
                this.isCriterionValid = false;
            }
        });
        return isValid;
    }

    onOpenEditCorrection() {
        if (this.statusId === 2) {
            this.isCorrectionEditorOpen = true;
        }
    }

    onCopyAnswerToCorrection() {
        let correction = this.expertCorrection !== null ? this.expertCorrection + ' ' : '';
        this.expertCorrection = correction + this.question.questionTextAnswer;
    }

    onConfirmAssessmentByExpert() {
        if (this.statusId === 3 && this.questionExpertId === this.userId) {
            this.main.confirmAssessmentByExpert(this.questionId, this.userId).subscribe(
                (res: any) => {
                    this.router.navigate(['/questions']);
                },
                (err: any) => {
                    console.log(err);
                }
            );
        }
    }

    onConfirmAssessmentByOperator() {
        if ((this.roleId === 1 || this.roleId === 2) && this.statusId === 4 ) {
            this.main.confirmAssessmentByOperator({testGradeIds: [this.questionId], operatorId: this.userId})
                .subscribe(
                    (res: any) => {
                        this.router.navigate(['/questions']);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                );
        }
    }

    onRejectAssessmentByOperator() {
        if ((this.roleId === 1 || this.roleId === 2) && this.statusId === 4 ) {
            this.main.rejectAssessmentByOperator({testGradeIds: [this.questionId]})
                .subscribe(
                    (res: any) => {
                        this.router.navigate(['/questions']);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                );
        }
    }

    generateCaptcha(){
        this.x = Math.floor(Math.random() * 11) + 1; // Generates a random integer between 0 and 10
        this.y = Math.floor(Math.random() * 11); // Generates a random integer between 0 and 10
        this.sum = this.x + this.y;
    }

    showCriterionInfo(criterion: any) {
        this.currentCriterionInfo = criterion;
        this.isShowCriterionInfo = true;
    }


    countWordsInHtml(text: string): number {
        text = this.extractText(text);
        // Remove leading and trailing whitespaces
        text = text.trim();

        // Split the text into words using whitespace as delimiter
        const words = text.split(/\s+/);

        // Count the number of words
        return words.length;
    }

    extractText(htmlText: string): string {
        // Create a temporary DOM element
        const tempElement = document.createElement('div');
        // Set the HTML content
        tempElement.innerHTML = htmlText;
        // Get the text content
        const textContent = tempElement.textContent || tempElement.innerText || '';
        // Clean up and return the text content
        return textContent.trim();
    }

    protected readonly editorConfig = editorConfig;
}



export const editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '15rem',
    minHeight: '15rem',
    placeholder: 'Текстовый редактор...',
    translate: 'no',
    defaultParagraphSeparator: 'p'
};
